<template>
  <div class="grid grid-cols-1 md:grid-cols-1 items-center justify-between text-left mt-12">
    <h1 class="text-2xl text-center z-10">
      <span v-if="claimedTokens">
        You Claimed {{ claimedTokens.length }} TACTICs Fragment{{
          claimedTokens.length > 1 ? 's' : ''
        }}
        <img class="inline -ml-6 align-baseline" src="../assets/partypotus.gif" />
      </span>
      <span v-else> Claim a TACTICs Fragment </span>
      <button
        v-if="!claimedTokens"
        class="text-blue-link hover:text-blue-hov"
        @click="
          () => {
            state.history.isOpen = true
          }
        "
      >
        <fa icon="history" class="h-5 w-5 -ml-4 align-baseline" />
      </button>
    </h1>
    <div
      v-if="claimedTokens"
      class="grid grid-cols-1"
      :class="{ 'md:grid-cols-2 gap-12 lg:grid-cols-3': claimedTokens.length > 1 }"
    >
      <div v-for="(tok, key) in claimedTokens" :key="key">
        <router-link :to="'/token/' + tok.tokenId">
          <img class="m-auto bounce-in-top my-3 max-w-xs" :src="tok.metadata.artifactUri" />
        </router-link>
      </div>
    </div>
    <button
      v-if="claimedTokens && myClaims == 6"
      class="
        text-sm
        uppercase
        py-4
        px-6
        bg-green-300
        hover:bg-green-400
        border-2 border-gray-700
        nes-btn
        is-disabled
        min-h-0
      "
      :disabled="true"
    >
      Looks like you've maxed out your claims for this period. <br />Claiming period resets
      {{ untilNextPeriod.fromNow() }}.
    </button>
    <div v-if="!initialized" class="text-center">Loading...</div>
    <div v-if="whitelisting" class="text-center">
      <fa icon="cog" class="animate-spin h-5 w-5 mr-3" />
      Ratifying... Please wait for block confirmation.
    </div>
    <div
      v-if="!claimsMaxed"
      class="
        grid grid-cols-1
        md:grid-cols-1
        gap-12
        items-center
        justify-between
        text-left
        mt-12
        pb-12
      "
    >
      <div
        v-if="!claiming && !confirmation && !whitelisting"
        class="nes-container is-rounded is-dark text-gray-600"
      >
        <img
          class="flex mx-auto mb-4 mt-2"
          src="https://tournament.pixeldebates.com/images/cards.png"
        />
        <h2 class="text-xl text-center text-gray-300">*** Skip the line and play today! ***</h2>

        <div class="pb-6 text-center">
          <b
            >Did you know there are many
            <a href="/market?fullDecksOnly=true"
              >pre-made decks available for sale in the marketplace?</a
            >
            Snag one and start debating today! <br /><br />Keep collecting faucet frags below in the
            meantime so you can be a market maker in the near future.</b
          >
        </div>
      </div>

      <div
        v-if="initialized && !claiming && !confirmation && !whitelisting"
        class="grid grid-cols-1 lg:grid-cols-2 gap-12"
      >
        <p class="nes-container is-rounded bg-brown-dark text-white p-5">
          Claiming period resets {{ untilNextPeriod.fromNow() }}.
          <span v-if="claimedPercent > 0"
            ><br /><br />{{ claimedPercent }}% of all FRAGMENTS have been claimed.</span
          >
        </p>
        <p class="nes-container is-rounded bg-brown-dark text-white p-5">
          Today’s activity:<br />
          My Claims : {{ myClaims }} of {{ maxFreeClaims + maxPaidClaims }} claimed.<br />
          All Claims: {{ totalClaims }} of {{ maxClaimsPerPeriod }} claimed.
        </p>
      </div>

      <div
        v-if="
          initialized && whitelisted && myClaims < maxFreeClaims && totalClaims < maxClaimsPerPeriod
        "
        class="grid gap-5"
      >
        <button
          class="
            text-sm
            uppercase
            py-4
            px-6
            bg-green-300
            hover:bg-green-400
            border-2 border-gray-700
            nes-btn
            is-primary
            h-20
          "
          :disabled="state.loading || claiming"
          @click="
            () => {
              claimToken(false)
            }
          "
        >
          <span v-if="claiming">
            <fa icon="cog" class="animate-spin h-5 w-5 mr-3" />
            Claiming... Pls wait a few mins for block confirmation.
          </span>
          <span v-if="!claiming">Claim My Free FRAGMENT</span>
        </button>
        <button
          v-if="!state.loading && !claiming"
          class="
            text-sm
            uppercase
            py-4
            px-6
            bg-green-300
            hover:bg-green-400
            border-2 border-gray-700
            nes-btn
            h-20
          "
          @click="
            () => {
              claimToken(false, true)
            }
          "
        >
          Batch Claim All {{ maxPaidClaims + maxFreeClaims - myClaims }} FRAGMENTS for
          {{ batchTotal }} $PXL
        </button>
      </div>

      <div
        v-else-if="
          initialized &&
          whitelisted &&
          myClaims >= maxFreeClaims &&
          myClaims < maxFreeClaims + maxPaidClaims &&
          totalClaims < maxClaimsPerPeriod
        "
        class="grid gap-5"
      >
        <button
          class="
            text-sm
            uppercase
            py-4
            px-6
            bg-green-300
            hover:bg-green-400
            border-2 border-gray-700
            nes-btn
            is-primary
            min-h-0
          "
          :disabled="state.loading || claiming"
          @click="
            () => {
              claimToken(true)
            }
          "
        >
          <span v-if="claiming">
            <fa icon="cog" class="animate-spin h-5 w-5 mr-3" />
            Claiming... Please wait for block confirmation.
          </span>
          <span v-if="!claiming"
            >Claim another FRAGMENT for {{ state.convertFromMutez(claimingPrice) }} $PXL. <br />UP
            TO {{ maxPaidClaims + maxFreeClaims }} PER PERIOD - FIRST ONE FREE - RESETS
            {{ untilNextPeriod.fromNow() }} <br />({{ myClaims }} /
            {{ maxPaidClaims + maxFreeClaims }} claimed)</span
          >
        </button>
        <button
          v-if="!state.loading && !claiming"
          class="
            text-sm
            uppercase
            py-4
            px-6
            bg-green-300
            hover:bg-green-400
            border-2 border-gray-700
            nes-btn
            h-20
          "
          @click="
            () => {
              claimToken(false, true)
            }
          "
        >
          Batch Claim All {{ maxPaidClaims + maxFreeClaims - myClaims }} FRAGMENTS for
          {{ batchTotal }} $PXL
        </button>
      </div>

      <div
        v-if="initialized && !whitelisted && !whitelisting"
        class="text-left nes-container is-rounded bg-brown-dark text-white"
      >
        Dear citizen, your address must be ratified before you can claim, but it's super quick. Are
        you a robot? Beep boop, boop beep beep?<br /><br />
        <vue-recaptcha
          v-if="initialized && !whitelisted"
          ref="vueRecaptcha2"
          class="m-auto flex-grow"
          site-key="6LfdFVgbAAAAABMpxAPeDfhdD2sKPOfOmHhjH-4S"
          size="normal"
          theme="light"
          :tabindex="0"
          @verify="recaptchaVerified"
          @expire="recaptchaExpired"
          @fail="recaptchaFailed"
        />
      </div>

      <SpeechBubble
        :img="state.images.grant"
        :show="!claiming && !claimedTokens && whitelisted"
        :potus="'Pres. Grant'"
      >
        <div v-if="initialized && myClaims >= maxFreeClaims + maxPaidClaims" class="text-xl">
          Looks like you've maxed out your claims for this period. <br /><br />
          Period resets {{ untilNextPeriod.fromNow() }}.
          <button
            class="text-blue-link hover:text-blue-hov block"
            @click="
              () => {
                state.history.isOpen = true
              }
            "
          >
            View History
          </button>
        </div>
        <div v-else-if="initialized && totalClaims >= maxClaimsPerPeriod" class="text-xl">
          Oops ... Looks like you're late today!<br /><br />
          All claims for this period have been maxed out. <br /><br />
          Period resets {{ untilNextPeriod.fromNow() }}.
          <button
            class="text-blue-link hover:text-blue-hov block"
            @click="
              () => {
                state.history.isOpen = true
              }
            "
          >
            View History
          </button>
        </div>
        <div v-else-if="!confirmation && !error">
          Claim your rightful share of TACTICs fragment tokens! Each time you claim I will "Grant"
          you a random TACTICs fragment token. You are limited to {{ maxFreeClaims }} free claim and
          {{ maxPaidClaims }} paid claims per period. A total of {{ maxClaimsPerPeriod }} TACTICs
          fragments can be claimed across all users per period.
        </div>
        <div v-else-if="confirmation" class="text-xl">
          Claim granted! You can keep claiming new tokens or visit
          <router-link class="" to="/me"> your collection. </router-link>
        </div>
        <div v-else-if="error" class="text-xl">
          Uh oh, looks like we are experiencing a high volume of claims and yours has been
          temporarily denied :( <br /><br />
          Please try again in a few moments.
        </div>
      </SpeechBubble>

      <SpeechBubble
        :img="state.images.pierce"
        :show="claiming || whitelisting"
        :potus="'Pres. Pierce'"
        :is-left="false"
      >
        Enjoy this random gif while u wait. You can also go do other stuff without affecting the
        transaction. <br />
        <img :src="nextGif" width="300" class="m-auto" />
      </SpeechBubble>
    </div>

    <SpeechBubble :img="state.images.grant" :show="claimsMaxed" :potus="'Pres. Grant'">
      Sorry friend, looks like all free FRAGMENTS have been claimed at this time. Come back after
      the next inauguration!
      <br />
      <br />
      You can still collect build your deck by trading with other constituents in the
      <router-link to="/market"> market! </router-link>
    </SpeechBubble>

    <SpeechBubble :img="state.images.eagleRight" :show="!claimedTokens" class="pb-16">
      Come on over to the official Pixel discord to chat with other constituents, post your trades
      and get support.<br />
      <a href="https://discord.gg/CbdbvwtwkM" target="_blank" class="text-blue-link">
        Click me
        <img class="inline align-baseline" width="25" src="../assets/discord.png" />
      </a>
    </SpeechBubble>
  </div>
</template>

<script>
import { OpKind } from '@taquito/taquito'
import axios from 'axios'
import { find, findIndex } from 'lodash'
import moment from 'moment'
import vueRecaptcha from 'vue3-recaptcha2'

import SpeechBubble from '../components/SpeechBubble'

export default {
  name: 'Claim',
  components: {
    SpeechBubble,
    vueRecaptcha,
  },
  data() {
    return {
      state: this.$root.$data.state,
      series: this.$root.$data.state.stats ? this.$root.$data.state.stats.series : [],
      myClaims: 0,
      periodStart: new Date().toISOString(),
      periodLen: 300,
      maxFreeClaims: 1,
      maxPaidClaims: 4,
      claimingPrice: 30000000,
      maxClaimsPerPeriod: 0,
      totalClaims: 0,
      claiming: false,
      confirmation: false,
      error: false,
      nextGif: null,
      getLoop: true,
      showHelp: true,
      untilNextPeriod: moment(),
      initialized: false,
      claimedTokens: null,
      whitelisted: false,
      whitelisting: false,
      whitelistEnabled: false,
      claimedPercent: 0,
      refLoading: false,
      refStatus: {
        isLoaded: false,
        bal: 0,
        qual: false,
        referredBy: false,
      },
    }
  },
  computed: {
    claimsMaxed() {
      return false
    },
    batchTotal() {
      let paidClaims = this.maxPaidClaims + this.maxFreeClaims - this.myClaims
      if (this.myClaims === 0) {
        paidClaims--
      }
      return this.state.convertFromMutez(paidClaims * this.claimingPrice)
    },
  },
  async mounted() {
    this.state.log('claim mounted')
    if (this.state.userAddress === null) {
      this.$router.push('/')
    } else {
      const sh = localStorage.getItem('showClaimHelp')
      this.showHelp = sh ? JSON.parse(sh) : true
      this.getLoop = true
      this.getClaimStorage()
      this.getReferralStatus()
      // if (this.state.stats) {
      //   const minted = this.state.stats.series.reduce((accumulator, currentValue) => { return accumulator + currentValue.levels[0].minted }, 0)
      //   this.claimedPercent = ((minted / (10000 * this.state.stats.series.length)) * 100).toFixed(2)
      // }
    }
  },
  async unmounted() {
    this.state.log('claim unmounted')
    this.getLoop = false
  },
  methods: {
    async getReferralStatus() {
      try {
        this.refLoading = true
        const resp = await axios({
          url: '/api/referralStatus',
          params: {
            userAddress: this.state.userAddress,
          },
        })
        this.refStatus.bal = resp.data.bal
        this.refStatus.qual = resp.data.qual
        this.refStatus.referredBy = resp.data.referredBy
        this.refStatus.isLoaded = true
        this.refLoading = false
      } catch (e) {
        console.error(e)
        this.refLoading = false
        await new Promise((resolve) => setTimeout(resolve, 5000))
        this.getReferralStatus()
      }
    },
    toggleHelp() {
      this.showHelp = false
      localStorage.setItem('showClaimHelp', JSON.stringify(false))
    },
    async recaptchaVerified(captchaResp) {
      try {
        this.claimedTokens = null
        this.whitelisting = true
        this.nextGif = this.state.gifs[Math.floor(Math.random() * this.state.gifs.length)]
        await axios({
          url: '/api/whitelist',
          method: 'POST',
          data: {
            address: this.state.userAddress,
            captcha: captchaResp,
          },
        })
      } catch (e) {
        this.whitelisting = false
      }
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset()
    },
    recaptchaFailed() {
      this.state.log('captcha failed')
    },
    async getClaimStorage() {
      if (!this.getLoop) {
        return
      }
      if (this.claiming) {
        await new Promise((resolve) => setTimeout(resolve, 15000))
        await this.getClaimStorage()
      }

      this.state.log('getClaimStorage')

      const claimResp = await axios({
        url: '/api/claimMeta',
      })

      this.periodStart = claimResp.data.meta.periodStart
      this.periodLen = claimResp.data.meta.periodLen
      this.claimingPrice = claimResp.data.meta.claimingPrice
      this.maxClaimsPerPeriod = claimResp.data.meta.maxClaimsPerPeriod
      this.maxFreeClaims = claimResp.data.meta.maxFreeClaims
      this.maxPaidClaims = claimResp.data.meta.maxPaidClaims
      this.whitelistEnabled = claimResp.data.meta.whitelistEnabled

      this.untilNextPeriod = moment(this.periodStart).add(this.periodLen, 'seconds')

      const wl = find(claimResp.data.meta.whitelist, (cl) => {
        return cl.address === this.state.userAddress
      })
      if (wl) {
        this.whitelisted = true
        this.whitelisting = false
      } else {
        this.whitelisted = false
      }

      // if whitelisting is not enabled, then treat it like it is already whitelisted
      if (this.whitelistEnabled === false) {
        this.whitelisted = true
      }
      const isNextPeriod = moment(this.periodStart)
        .add(this.periodLen, 'seconds')
        .isBefore(moment())
      if (!isNextPeriod) {
        const mc = find(claimResp.data.meta.claimers, (cl) => {
          return cl.address === this.state.userAddress
        })
        this.myClaims = mc ? mc.value : 0
        this.totalClaims = claimResp.data.meta.totalClaims
      } else {
        this.myClaims = 0
        this.totalClaims = 0
        this.untilNextPeriod = moment().add(this.periodLen, 'seconds')
      }
      this.initialized = true
      this.state.log(this.$data)
      // this.state.loading = false
      // this.state.log(this.$data)
      await new Promise((resolve) => setTimeout(resolve, 15000))
      await this.getClaimStorage()
    },
    async claimToken(paid, all = false) {
      this.state.loading = true
      this.claimedTokens = null
      this.error = false
      this.nextGif = this.state.gifs[Math.floor(Math.random() * this.state.gifs.length)]
      this.claiming = true
      this.confirmation = false
      if (this.state.userAddress === null) {
        await this.state.connectWallet()
      }
      try {
        let op = null
        const opts = {}

        const transactions = []

        const debatesReferrer = localStorage.getItem('debatesReferrer')
        if (debatesReferrer && this.refStatus.referredBy === false) {
          transactions.push({
            kind: OpKind.TRANSACTION,
            ...this.state.ppReferrer.methods.set_referred_by(debatesReferrer).toTransferParams(),
          })
        }

        const operators = [
          {
            add_operator: {
              owner: this.state.userAddress,
              operator: process.env.VUE_APP_PP_CLAIMER,
              token_id: 0,
            },
          },
        ]
        transactions.push({
          kind: OpKind.TRANSACTION,
          ...this.state.ppPixel.methods.update_operators(operators).toTransferParams(),
        })

        let paidClaims = this.maxPaidClaims + this.maxFreeClaims - this.myClaims
        if (this.myClaims === 0) {
          paidClaims--
          transactions.push({
            kind: OpKind.TRANSACTION,
            ...this.state.ppClaimer.methods.claim_free(null).toTransferParams(),
          })
        }

        let total = all ? paidClaims : this.myClaims === 0 ? 0 : 1
        this.state.log('total', total)
        for (let i = 0; i < total; i++) {
          transactions.push({
            kind: OpKind.TRANSACTION,
            ...this.state.ppClaimer.methods.claim_paid(null).toTransferParams(),
            ...opts,
          })
        }
        const batch = await this.state.tezos.wallet.batch(transactions)
        op = await batch.send()

        await op.confirmation(1)
        const claimHash = op.opHash

        let done = false
        while (!done) {
          let claimedResp = await axios({
            url: '/api/getClaimedToken',
            params: {
              hash: claimHash,
            },
          })
          if (claimedResp.data.error) {
            // display the error message.
            this.error = true
            done = true
          } else if (claimedResp.data.claimed) {
            this.confirmation = true
            // let tknSmry = ''
            await Promise.all(
              claimedResp.data.claimed.map(async (tokenId) => {
                let claimedToken = find(this.state.allTokens, (tok) => {
                  return tok.tokenId === tokenId
                })
                if (claimedToken) {
                  if (this.claimedTokens === null) {
                    this.claimedTokens = []
                  }
                  this.claimedTokens.push(claimedToken)
                  const idx = findIndex(this.state.myTokens, (mt) => mt.tokenId === tokenId)
                  if (idx > -1) {
                    this.state.myTokens[idx].balance += 1
                  } else {
                    this.state.myTokens.push({ ...claimedToken, balance: 1, upgrading: false })
                  }
                  this.state.sortTokens(this.state.sortBy, true)

                  // tknSmry = `${tknSmry}${claimedToken.metadata.name}`
                }
              })
            )
            const usrName = this.state.userAlias
              ? this.state.userAlias
              : this.state.formattedAddr(this.state.userAddress)
            await axios({
              method: 'post',
              url: '/api/sendActivity',
              data: {
                content: `${usrName} claimed ${claimedResp.data.claimed.length} TACTICs fragments! <:pixelpotus:850438439349780530>`,
              },
            })
            done = true
          } else {
            await new Promise((resolve) => setTimeout(resolve, 5000))
          }
        }

        this.myClaims += this.claimedTokens.length
        this.claiming = false
        this.state.loading = false
        this.state.loadBalance()
        setTimeout(() => {
          this.state.loadMyTokens(true, true)
        }, 5000)
      } catch (e) {
        this.state.loading = false
        this.claiming = false
        console.error('Unable to claim token', e)
        if (e.name && e.name === 'BeaconWalletNotInitialized') {
          this.state.userAddress = null
          this.$router.push('/')
        }
      }
    },
  },
}
</script>
