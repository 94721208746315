export default (state) => {
  const links = [
    {
      label: 'Home',
      path: '/',
      wallet: false,
    },
    {
      label: 'Claim',
      path: '/claim',
      wallet: true,
    },
    {
      label: 'Me',
      path: '/me',
      wallet: true,
    },
    {
      label: 'Market',
      path: '/market',
      wallet: true,
    },
    {
      label: 'Stats',
      path: '/stats',
      wallet: false,
    },
    {
      label: 'Leaders',
      path: '/leaderboard',
      wallet: false,
    },
    {
      label: 'Gameplay',
      path: '/gameplay',
      wallet: false,
    },
  ]
  return links.filter((link) => {
    if (!state.userAddress) {
      return !link.wallet
    }
    return true
  })
}
