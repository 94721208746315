<template>
  <div
    class="
      grid grid-cols-1
      md:grid-cols-1
      gap-0
      items-center
      justify-between
      text-left
      mt-12
      overflow-hidden
    "
  >
    <h1 class="text-2xl text-center">
      My Collection<button
        class="text-blue-link hover:text-blue-hov"
        @click="
          () => {
            state.history.isOpen = true
          }
        "
      >
        <fa icon="history" class="h-5 w-5 align-baseline" />
      </button>
      <br />
      <span class="text-xs"
        >Last refresh: <br />{{ myTokensTimestamp ? myTokensTimestamp.fromNow() : 'never' }}
        <button
          v-if="myTokensTimestamp"
          class="text-blue-link hover:text-blue-hov"
          alt="Refresh Tokens"
          :disabled="state.myTokensLoading"
          @click="refresh()"
        >
          <fa
            icon="sync"
            :class="{ 'animate-spin': state.myTokensLoading }"
            class="h-5 w-5 align-bottom"
          /></button
      ></span>
    </h1>

    <div v-if="state.loading || !state.myTokensLoaded" class="text-center">Loading...</div>

    <div class="rounded-full mb-2 py-2 px-3 bg-black text-white m-auto">
      Alias: {{ state.userAlias || 'none' }}
      <button
        class="text-blue-link hover:text-blue-hov"
        @click="
          () => {
            showAliasInput = !showAliasInput
            aliasInput = state.userAlias
          }
        "
      >
        <fa icon="edit" class="h-5 w-5 align-baseline" />
      </button>
    </div>
    <div v-if="showAliasInput" class="m-auto nes-container is-rounded is-dark">
      <div class="nes-field mb-2 grid grid-cols-3 text-xs">
        <label for="alias_field" class="col-span-3 text-xs"
          >Set an alias up to 15 chars long for
          {{ state.convertFromMutez(aliaserCost) }}tez.<br />It will be displayed everywhere in
          place of<br />
          your address. Pls keep it PG :)</label
        >
        <input
          id="alias_field"
          v-model="aliasInput"
          type="text"
          class="nes-input col-span-2 text-black text-xs"
          :maxlength="aliaserMaxLen"
        />
        <button class="nes-btn is-primary col-span-1" :disabled="settingAlias" @click="setAlias">
          <fa v-if="settingAlias" icon="cog" class="animate-spin h-5 w-5 align-baseline" />
          Sav<span v-if="settingAlias">ing</span><span v-else>e</span>
        </button>
      </div>
    </div>

    <div v-if="state.myTokensLoaded" class="flex flex-col border-t border-b border-dashed">
      <div
        class="flex flex-col items-center justify-center p-5 bg-yellow-500 text-black nes-container"
      >
        <h2 class="text-2xl">
          Refer & Earn
          <button class="" @click="toggleShowRefer()">
            <fa v-if="!showRefer" icon="chevron-right" class="text-black h-5 w-5 ml-1" />
            <fa v-if="showRefer" icon="chevron-down" class="text-black h-5 w-5 ml-1" />
          </button>
        </h2>
        <span v-show="showRefer">
          <div
            class="
              flex flex-col
              items-center
              justify-center
              border-black border-2 border-dashed
              p-3
            "
          >
            Refer your friends and earn 20% of their claim and upgrade fees. Thanks for helping us
            spread the love!
          </div>
          <div
            v-if="refStatus.isLoaded"
            class="
              flex flex-col
              items-center
              justify-center
              border-black border-2 border-dashed
              p-3
            "
          >
            <div v-if="refStatus.referredBy === false" class="col-span-1">
              <button
                class="nes-btn is-primary"
                :class="{ 'is-disabled': refLoading }"
                :disabled="refLoading"
                @click="genRefLink"
              >
                <fa v-if="refLoading" icon="cog" class="animate-spin h-5 w-5 align-baseline" />
                Generate Referral Link
              </button>
            </div>
            <div v-else class="col-span-1">
              <h3 class="text-left mb-4">Your Referral Link:</h3>
              <div class="grid grid-cols-12" style="">
                <input type="text" disabled :value="shareUrl" class="nes-input col-span-11" />
                <button class="nes-btn is-primary align-center col-span-1" @click="copyShareUrl">
                  <fa icon="copy" class="align-baseline" />
                </button>
              </div>
              <p class="flex flex-row gap-2">
                <ShareNetwork
                  network="facebook"
                  :url="shareUrl"
                  :title="`You're invited to try PixelDebates, an NFT-powered play-to-earn trading card game.`"
                  :description="`You're invited to try PixelDebates, an NFT-powered play-to-earn trading card game.`"
                  hashtags="nft,artforge"
                >
                  <fa :icon="['fab', 'facebook']" class="align-baseline" />
                </ShareNetwork>
                <ShareNetwork
                  network="twitter"
                  :url="shareUrl"
                  :title="`You're invited to try PixelDebates, an NFT-powered play-to-earn trading card game.`"
                  :description="`You're invited to try PixelDebates, an NFT-powered play-to-earn trading card game.`"
                  twitter-user="pixelpotus"
                >
                  <fa :icon="['fab', 'twitter']" class="align-baseline" />
                </ShareNetwork>
                <ShareNetwork
                  network="reddit"
                  :url="shareUrl"
                  :title="`You're invited to try PixelDebates, an NFT-powered play-to-earn trading card game.`"
                >
                  <fa :icon="['fab', 'reddit']" class="align-baseline" />
                </ShareNetwork>
              </p>
              <hr class="mt-4" />
              <h3 class="text-left mb-4 mt-4">
                Your Referral Rewards Balance:
                {{ (refStatus.bal / 1000000).toFixed(3) }} $PXL<button
                  class="nes-btn is-primary"
                  :class="{ 'is-disabled': refLoading }"
                  :disabled="refLoading"
                  @click="claimRefBalance"
                >
                  <fa v-if="refLoading" icon="cog" class="animate-spin h-5 w-5 align-baseline" />
                  Claim
                </button>
              </h3>
            </div>
          </div>
        </span>
      </div>

      <div class="flex flex-col items-center justify-center p-5 bg-white text-black nes-container">
        <h2 class="text-2xl">
          How to Advance
          <button @click="toggleShowHowTo()">
            <fa
              v-if="!showHowTo"
              icon="chevron-right"
              class="text-blue-link hover:text-blue-hov h-5 w-5 ml-1"
            />
            <fa
              v-if="showHowTo"
              icon="chevron-down"
              class="text-blue-link hover:text-blue-hov h-5 w-5 ml-1"
            />
          </button>
        </h2>
        <span v-show="showHowTo">
          <img v-if="myTokens.length" class="w-full" src="@/assets/level-up.png" />
          <div
            class="
              flex flex-col
              items-center
              justify-center
              border-black border-2 border-dashed
              p-3
            "
          >
            Collect fragments 1, 2 & 3 of a single card and spend
            {{ state.convertFromMutez(calcPxlCost(state.allRecipes[0].req_pxl)) }} $PXL to forge
            them into a 1 star tactic card.
          </div>
          <div
            class="
              flex flex-col
              items-center
              justify-center
              border-black border-2 border-dashed
              p-3
            "
          >
            Collect {{ state.allRecipes[1].req_items[0].amount }}x of a single 1 star card and spend
            {{ state.convertFromMutez(calcPxlCost(state.allRecipes[1].req_pxl)) }} $PXL to upgrade
            them into a single 2 star tactic card.
          </div>
          <div
            class="
              flex flex-col
              items-center
              justify-center
              border-black border-2 border-dashed
              p-3
            "
          >
            Collect {{ state.allRecipes[2].req_items[0].amount }}x of a single 2 star card and spend
            {{ state.convertFromMutez(calcPxlCost(state.allRecipes[2].req_pxl)) }} $PXL to upgrade
            them into a single 3 star tactic card.
          </div>
        </span>
      </div>
      <div class="grid grid-cols-1 bg-brown-dark text-white nes-container">
        <div class="">
          <div class="flex flex-row items-center justify-center">
            <span for="filter_trades" class="text-md font-medium">Filter: </span>
            <input
              id="filter_trades"
              v-model="filter"
              type="text"
              name="filter_trades"
              class="
                p-2
                border-2
                focus:ring-blue-500 focus:border-blue-500
                block
                shadow-sm
                text-xs
                sm:text-sm
                border-gray-300
                rounded-none
                text-black
              "
              placeholder="card title"
              @input="updateFilters()"
            />
          </div>
          <div class="flex flex-row items-end justify-center">
            <div
              class="
                relative
                inline-block
                w-10
                mt-2
                mr-2
                ml-2
                align-middle
                select-none
                transition
                duration-200
                ease-in
              "
            >
              <input
                id="toggleShowUpgradable"
                v-model="showUpgradable"
                type="checkbox"
                name="toggleShowUpgradable"
                class="
                  toggle-checkbox
                  absolute
                  block
                  w-6
                  h-6
                  rounded-full
                  bg-white
                  border-4
                  appearance-none
                  cursor-pointer
                "
                @input="updateCheckbox($event)"
              />
              <label
                for="toggleShowUpgradable"
                class="
                  toggle-label
                  block
                  overflow-hidden
                  h-6
                  rounded-full
                  bg-gray-300
                  cursor-pointer
                "
              />
            </div>
            <label for="toggleShowUpgradable" class="text-xs inline cursor-pointer"
              >Only show upgradable</label
            >
          </div>
        </div>
        <div v-if="state.myStats" class="nes-container is-rounded">
          <label for="filter_trades" class="block text-md font-medium text-gray-700"
            >My Stats</label
          >
          <span class="text-xs">
            Rank: <span class="text-green-700">{{ state.myStats.rank }}</span
            >, Rarity: <span class="text-green-700">{{ state.myStats.weighted }}</span
            >, Cards: <span class="text-green-700">{{ state.myStats.total }}</span
            >, Trades: <span class="text-green-700">{{ state.myStats.trades }}</span
            >, Barters: <span class="text-green-700">{{ state.myStats.barters }}</span
            >, Sales:
            <span class="text-green-700">{{ state.convertFromMutez(state.myStats.sales) }}ꜩ</span>
          </span>
        </div>
      </div>

      <div
        v-if="visibleTokens.length"
        class="flex flex-col items-center justify-center divide-y divide-gray-700"
      >
        <div
          v-for="(card, tkey) in visibleTokens.slice(offset, offset + limit)"
          :key="tkey"
          class="grid grid-cols-12 items-start justify-start w-full p-3 gap-2"
        >
          <div
            :class="
              getHasOneFullCard(card)
                ? 'col-span-6 lg:col-span-2'
                : 'opacity-25 col-span-6 lg:col-span-2'
            "
          >
            <img :src="card.cards[0].metadata.displayUri" alt="" />
          </div>
          <div
            class="
              col-span-6
              lg:col-span-4
              flex flex-col
              items-top
              justify-items-start
              text-xs
              border-black border-2 border-dotted
              h-full
              p-2
            "
          >
            <span class="text-xl break-words">{{ card.title }}</span
            ><br />
            <div class="flex py-1">
              <div>Alignment:</div>
              <div class="flex-grow border-black border-b-2 border-dotted"></div>
              <div>{{ card.alignment }}</div>
            </div>
            <div class="flex py-1">
              <div>Strength:</div>
              <div class="flex-grow border-black border-b-2 border-dotted"></div>
              <div>{{ card.strength }}</div>
            </div>
            <div class="flex py-1">
              <div>Cost:</div>
              <div class="flex-grow border-black border-b-2 border-dotted"></div>
              <div>{{ card.cost }}</div>
            </div>
            <div v-if="card.effect.length" class="flex flex-col py-1">
              <div>Effect:</div>
              <div class="text-gray-700">{{ card.effect }}</div>
            </div>
            <!-- <br /><br />
            <div
              class="
                flex flex-col flex-shrink-0
                items-top
                justify-items-start
                text-xs
                divide-y divide-gray-300
              "
            >
              <span class="text-xl break-words">Balances</span><br />
              <div
                v-for="(tok, idx) in card.cards"
                v-show="!upgrading || card.upgrading"
                :key="tok.tokenId"
                class="flex flex-row items-center justify-items-left h-10"
                :class="{
                  'text-green-700':
                    (idx == 0 && card.upgradeTo2 > 0) || (idx == 1 && card.upgradeTo3 > 0),
                }"
              >
                <span v-if="(idx == 0 && card.upgradeTo2 > 0) || (idx == 1 && card.upgradeTo3 > 0)"
                  ><fa icon="check-circle" class="h-4 w-5 mr-2" /></span
                >{{ tok.metadata.name.split(' - ')[1] }}: <span>{{ tok.balance }}</span>
                <span v-if="(idx == 0 && card.upgradeTo2 > 0) || (idx == 1 && card.upgradeTo3 > 0)"
                  ><button class="rounded-md p-1 ml-1" @click="upgrade(card, tok.recipe)">
                    <img width="25" src="../assets/level-up-triangle.png" /></button
                ></span>
              </div>
            </div> -->
          </div>
          <div
            class="
              col-span-12
              md:col-span-6
              lg:col-span-3
              flex flex-row
              items-top
              justify-items-start
              h-full
              text-xs
              border-black border-2 border-dotted
            "
          >
            <div
              class="
                flex flex-col
                items-top
                justify-items-start
                h-full
                text-xs
                p-2
                divide-y divide-gray-300
              "
            >
              <h2 class="text-xl">Fragments</h2>
              <div
                v-for="tok in card.fragments"
                :key="tok.tokenId"
                :class="{ 'text-green-700': tok.balance > 0, 'text-red-700': tok.balance == 0 }"
                class="flex flex-row items-center justify-items-left h-10 pl-2"
              >
                <span v-if="tok.balance > 0"><fa icon="check-circle" class="h-4 w-5 mr-2" /></span
                ><span v-if="tok.balance == 0"><fa icon="times-circle" class="h-4 w-5 mr-2" /></span
                >{{ tok.metadata.name.split(' - ')[1] }}: <span>{{ tok.balance }}</span>
              </div>
            </div>
            <button
              v-if="card.fragUpgrades > 0"
              class=""
              @click="startUpgrade(card, 'Fragment', card.fragments[0].recipeId)"
            >
              <img src="../assets/level-up-triangle.png" />
            </button>
          </div>
          <div
            class="
              col-span-12
              md:col-span-6
              lg:col-span-3
              flex flex-col flex-shrink-0
              items-top
              justify-items-start
              h-full
              text-xs
              border-black border-2 border-dotted
              p-2
              divide-y divide-gray-300
            "
          >
            <h2 class="text-xl">Full Cards</h2>
            <div
              v-for="(tok, idx) in card.cards"
              :key="tok.tokenId"
              class="flex flex-row items-center justify-items-left h-10 pl-2"
              :class="{
                'text-green-700':
                  (idx == 0 && card.upgradeTo2 > 0) || (idx == 1 && card.upgradeTo3 > 0),
              }"
            >
              <span v-if="(idx == 0 && card.upgradeTo2 > 0) || (idx == 1 && card.upgradeTo3 > 0)"
                ><fa icon="check-circle" class="h-4 w-5 mr-2" /></span
              >{{ tok.metadata.name.split(' - ')[1] }}: <span>{{ tok.balance }}</span>
              <span v-if="(idx == 0 && card.upgradeTo2 > 0) || (idx == 1 && card.upgradeTo3 > 0)"
                ><button class="rounded-md p-1 ml-1" @click="startUpgrade(card, '', tok.recipeId)">
                  <img width="25" src="../assets/level-up-triangle.png" /></button
              ></span>
            </div>
          </div>
        </div>
        <div v-if="pages > 0 && !upgrading" class="flex p-10">
          <div class="m-auto">
            <button @click="prevPage()">
              <fa
                icon="chevron-circle-left"
                class="text-blue-link hover:text-blue-hov h-5 w-5 mr-1"
              /></button
            ><span class="">Page {{ offset / limit + 1 }} of {{ pages }} </span
            ><button @click="nextPage()">
              <fa
                icon="chevron-circle-right"
                class="text-blue-link hover:text-blue-hov h-5 w-5 ml-1"
              />
            </button>
          </div>
        </div>
        <!-- <SpeechBubble
          :img="state.images.pierce"
          :show="upgrading"
          :potus="'Pres. Pierce'"
          :is-left="false"
        >
          Enjoy this random gif while u wait. You can also go do other stuff without affecting
          the transaction. <br />
          <img :src="nextGif" width="300" class="m-auto" />
        </SpeechBubble> -->
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    v-if="showModal"
    id="modal-upgrade"
    class="
      flex
      overflow-x-hidden overflow-y-auto
      fixed
      inset-0
      z-50
      outline-none
      focus:outline-none
      justify-center
      items-center
    "
  >
    <div class="relative w-auto my-6 mx-auto max-w-6xl">
      <!--content-->
      <div
        class="
          border-0
          rounded-lg
          shadow-lg
          relative
          flex flex-col
          w-full
          bg-white
          outline-none
          focus:outline-none
          text-left
        "
      >
        <!--header-->
        <div
          class="
            flex
            items-start
            justify-between
            p-5
            border-b border-solid border-gray-200
            rounded-t
          "
        >
          <h3 v-if="!upgradeCompleted" class="text-3xl font-semibold">
            Upgrade to {{ upgradeRecipe?.crafted_item?.token?.rarity }}?
          </h3>
          <h3 v-if="upgradeCompleted" class="text-3xl font-semibold">Upgrade Complete!</h3>
          <button
            class="
              p-1
              ml-auto
              bg-transparent
              border-0
              text-gray-300
              float-right
              text-3xl
              leading-none
              font-semibold
              outline-none
              focus:outline-none
            "
            @click="cancelUpgrade()"
          >
            <span class="bg-transparent h-6 w-6 text-2xl block outline-none focus:outline-none">
              <fa icon="times-circle" />
            </span>
          </button>
        </div>
        <!--body-->
        <div class="relative p-6 flex-col h-1/3">
          <p v-if="!upgradeCompleted" class="my-4 text-gray-600 text-lg leading-relaxed">
            This process will destroy your token(s) and mint a new
            {{ upgradeRecipe.crafted_item.token.rarity }} tactic.
          </p>
          <div class="flex flex-row items-center justify-center">
            <div
              v-for="(item, key) of upgradeRecipe.req_items"
              v-show="!upgradeCompleted"
              :key="key"
              class="flex-shrink"
            >
              <img
                :class="{
                  'vibrate-1': upgradeCard.upgrading,
                  'max-w-xs': upgradeRecipe.req_items.length == 1,
                }"
                :src="item?.token?.metadata?.displayUri"
              />
            </div>
            <fa
              v-if="!upgradeCompleted"
              icon="long-arrow-alt-right"
              class="text-5xl align-middle m-5"
            />
            <div class="flex-shrink">
              <img
                class="max-w-xs"
                :class="{ 'vibrate-3': upgradeCard.upgrading, heartbeat: upgradeCompleted }"
                :src="upgradeRecipe?.crafted_item?.token?.metadata?.displayUri"
              />
            </div>
          </div>
        </div>
        <!--footer-->
        <div
          class="flex items-center justify-end p-6 border-t border-solid border-gray-200 rounded-b"
        >
          <button
            class="
              text-blue-500
              background-transparent
              font-bold
              uppercase
              px-6
              py-2
              text-sm
              outline-none
              focus:outline-none
              mr-1
              mb-1
              ease-linear
              transition-all
              duration-150
            "
            type="button"
            @click="toggleMusic()"
          >
            <fa v-if="isMuted" icon="volume-off" class="h-5 w-5 align-bottom" />
            <fa v-if="isMuted === false" icon="volume-up" class="h-5 w-5 align-bottom" />
          </button>
          <div v-if="upgrading">
            <fa icon="sync" class="animate-spin h-5 w-5 align-bottom" /> Please wait for block
            confirmation.
          </div>
          <button
            v-if="!upgradeCompleted && !upgrading"
            class="
              text-blue-500
              background-transparent
              font-bold
              uppercase
              px-6
              py-2
              text-sm
              outline-none
              focus:outline-none
              mr-1
              mb-1
              ease-linear
              transition-all
              duration-150
            "
            type="button"
            @click="cancelUpgrade()"
          >
            Cancel
          </button>
          <button
            v-if="!upgradeCompleted && !upgrading"
            class="
              nes-btn
              is-primary
              text-white
              font-bold
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              mb-1
              ease-linear
              transition-all
              duration-150
            "
            type="button"
            @click="upgrade(upgradeCard, upgradeRecipe)"
          >
            Upgrade ({{ state.convertFromMutez(calcPxlCost(upgradeRecipe.req_pxl)) }} $PXL)
          </button>
          <button
            v-if="!upgradeCompleted && !upgrading && upgradeTotal > 1"
            class="
              nes-btn
              is-primary
              text-white
              font-bold
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              mb-1
              ease-linear
              transition-all
              duration-150
            "
            type="button"
            @click="upgrade(upgradeCard, upgradeRecipe, upgradeTotal)"
          >
            x{{ upgradeTotal }} ({{
              state.convertFromMutez(calcPxlCost(upgradeRecipe.req_pxl) * upgradeTotal)
            }}
            $PXL)
          </button>
          <button
            v-if="upgradeCompleted"
            class="
              nes-btn
              is-primary
              text-white
              font-bold
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              mb-1
              ease-linear
              transition-all
              duration-150
            "
            type="button"
            @click="cancelUpgrade()"
          >
            Finish
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="showModal"
    id="modal-upgrade-backdrop"
    class="flex opacity-25 fixed inset-0 z-40 bg-black"
  ></div>
  <audio id="audio" ref="audio" preload loop>
    <source src="../assets/PixelPotusLeaderBoardTrack.mp3" type="audio/mpeg" />
  </audio>
</template>

<script>
import { OpKind } from '@taquito/taquito'
import axios from 'axios'
import { debounce, filter, find, findIndex, forEach, groupBy } from 'lodash'
import { copyText } from 'vue3-clipboard'

export default {
  name: 'MyCollection',
  components: {},
  data() {
    return {
      state: this.$root.$data.state,
      myTokens: this.$root.$data.state.myTokens,
      visibleTokens: [],
      offset: 0,
      limit: 20,
      confirmation: false,
      showHelp: true,
      upgrading: false,
      nextGif: null,
      batchSelect: false,
      batchTokens: [],
      rarities: [],
      raritiesOptions: ['Fragment', '1 Star', '2 Star', '3 Star'],
      filter: '',
      showUpgradable: false,
      showAliasInput: false,
      aliaserMaxLen: 15,
      settingAlias: false,
      aliaserCost: 250000,
      showHowTo: true,
      showRefer: true,
      upgradeCard: null,
      upgradeType: null,
      upgradeRecipe: null,
      upgradeTotal: 0,
      upgradeCompleted: false,
      showModal: false,
      isMuted: false,
      refLoading: false,
      refStatus: {
        isLoaded: false,
        bal: 0,
        qual: false,
        referredBy: false,
      },
    }
  },
  computed: {
    pages() {
      if (!this.myTokens) {
        return 1
      }
      let tot = parseInt((this.visibleTokens.length / this.limit).toFixed(0))
      if (tot < this.visibleTokens.length / this.limit) {
        tot++
      }
      return tot
    },
    myTokensTimestamp() {
      return this.state.myTokensTimestamp
    },
    cards() {
      let cards = []
      const clonedToks = JSON.parse(JSON.stringify(this.state.allTokens))
      let allCards = groupBy(clonedToks, 'seriesId')
      forEach(allCards, (tokens, seriesId) => {
        let hasBal = false
        tokens = tokens.map((tok) => {
          const myTok = find(this.myTokens, (mt) => mt.tokenId === tok.tokenId)
          if (myTok) {
            hasBal = true
            tok.balance = myTok.balance
          } else {
            tok.balance = 0
          }
          return tok
        })

        const fragments = filter(tokens, (tok) => tok.rarity === 'Fragment').sort((a, b) => {
          return a.tokenId - b.tokenId
        })
        let fragUpgrades = 100000
        fragments.forEach((frag) => {
          if (frag.balance < fragUpgrades) {
            fragUpgrades = frag.balance
          }
        })

        const cardsFilter = filter(tokens, (tok) => tok.rarity !== 'Fragment').sort((a, b) => {
          return a.tokenId - b.tokenId
        })
        let upgradeTo2 = Math.floor(cardsFilter[0].balance / 10)
        let upgradeTo3 = Math.floor(cardsFilter[1].balance / 15)

        let title = tokens[0].metadata.name.split(' - ')[0]
        if (hasBal) {
          cards.push({
            seriesId,
            title,
            strength: tokens[0].metadata.strength,
            cost: tokens[0].metadata.cost,
            effect: tokens[0].metadata.effect,
            alignment: tokens[0].metadata.alignment,
            fragments,
            fragUpgrades,
            cards: cardsFilter,
            upgradeTo2,
            upgradeTo3,
            upgrading: false,
          })
        }
      })
      cards.sort((a, b) => {
        return a.title.localeCompare(b.title)
      })
      return cards
    },
    shareUrl() {
      return `https://www.pixeldebates.com?ref=${this.state.userAddress}`
    },
  },
  async mounted() {
    if (this.state.userAddress === null) {
      this.$router.push('/')
    }
    this.state.log('mounted MyCollection', this.state.myTokens, this.state.myTokensLoaded)
    const sh = localStorage.getItem('showCollectionHelp')
    this.showHelp = sh ? JSON.parse(sh) : true
    this.visibleTokens = this.cards

    const showHowTo = localStorage.getItem('showHowTo')
    this.showHowTo = showHowTo ? JSON.parse(showHowTo) : true

    const showRefer = localStorage.getItem('showRefer')
    this.showRefer = showRefer ? JSON.parse(showRefer) : false

    // this.getMyStats()
    this.updateFilters()
    this.getReferralStatus()
  },
  methods: {
    copyShareUrl() {
      copyText(this.shareUrl, undefined, (error) => {
        if (error) {
          alert('Can not copy referral link')
          console.error(error)
        }
      })
    },
    async genRefLink() {
      try {
        this.refLoading = true
        const debatesReferrer = localStorage.getItem('debatesReferrer')
          ? localStorage.getItem('debatesReferrer')
          : process.env.VUE_APP_ROOT_REF
        const op = await this.state.ppReferrer.methods.set_referred_by(debatesReferrer).send()
        await op.confirmation(2)
        await this.getReferralStatus()
      } catch (e) {
        this.refLoading = false
        console.error(e)
        // alert('Error: ' + e?.message)
      }
    },
    async claimRefBalance() {
      try {
        this.refLoading = true
        const op = await this.state.ppReferrer.methods.claim().send()
        await op.confirmation(1)
        await this.getReferralStatus()
      } catch (e) {
        this.refLoading = false
        console.error(e)
        // alert('Error claiming rewards: ' + e?.message)
      }
    },
    async getReferralStatus() {
      try {
        this.refLoading = true
        const resp = await axios({
          url: '/api/referralStatus',
          params: {
            userAddress: this.state.userAddress,
          },
        })
        this.refStatus.bal = resp.data.bal
        this.refStatus.qual = resp.data.qual
        this.refStatus.referredBy = resp.data.referredBy
        this.refStatus.isLoaded = true
        this.refLoading = false
      } catch (e) {
        console.error(e)
        this.refLoading = false
        await new Promise((resolve) => setTimeout(resolve, 5000))
        this.getReferralStatus()
      }
    },
    async refresh() {
      await this.state.loadMyTokens(true, true)
      this.debouncedUpdate()
      this.getReferralStatus()
    },
    startUpgrade(card, type, recipeId) {
      this.upgradeCard = card
      this.upgradeType = type
      const recipe = find(this.state.allRecipes, (r) => r.id === recipeId)
      this.upgradeRecipe = recipe
      this.upgradeCompleted = false

      this.upgradeTotal = 1
      if (recipe.crafted_item.token.rarity == '1 Star') {
        this.upgradeTotal = card.fragUpgrades
      } else if (recipe.crafted_item.token.rarity == '2 Star') {
        this.upgradeTotal = card.upgradeTo2
      } else if (recipe.crafted_item.token.rarity == '3 Star') {
        this.upgradeTotal = card.upgradeTo3
      }
      if (this.upgradeTotal > 10) {
        this.upgradeTotal = 10
      }

      this.toggleModal()
    },
    toggleMusic() {
      if (this.isMuted === false) {
        this.$refs.audio.pause()
      } else {
        this.$refs.audio.play()
      }
      this.isMuted = !this.isMuted
    },
    cancelUpgrade() {
      this.upgradeCard = null
      this.upgradeType = null
      this.upgradeRecipe = null
      this.upgradeCompleted = false
      this.toggleModal()
      this.$refs.audio.pause()
    },
    toggleModal() {
      this.showModal = !this.showModal
    },
    toggleShowHowTo() {
      this.showHowTo = !this.showHowTo
      localStorage.setItem('showHowTo', this.showHowTo)
    },
    toggleShowRefer() {
      this.showRefer = !this.showRefer
      localStorage.setItem('showRefer', this.showRefer)
    },
    sortTokens(column) {
      this.state.sortTokens(column)
      this.debouncedUpdate()
    },
    calcPxlCost(basePxl) {
      return (basePxl * this.state.pxlMod) / 1000
    },
    async updateCheckbox() {
      // await new Promise((resolve) => setTimeout(resolve, 150))
      this.debouncedUpdate()
    },
    async updateFilters() {
      if (this.state.myTokens.length === 0) {
        await new Promise((resolve) => setTimeout(resolve, 500))
        await this.updateFilters()
      } else {
        this.debouncedUpdate()
      }
    },
    debouncedUpdate: debounce(function () {
      this.offset = 0
      this.visibleTokens = filter(this.cards, (card) => {
        let sRet = true
        if (
          this.filter !== '' &&
          card.title.toLowerCase().indexOf(this.filter.toLowerCase()) == -1
        ) {
          sRet = false
        }
        if (
          this.showUpgradable &&
          card.fragUpgrades == 0 &&
          card.upgradeTo2 == 0 &&
          card.upgradeTo3 == 0
        ) {
          sRet = false
        }
        return sRet
      })
    }, 50),
    selectForBatch(e, tok) {
      if (e.target.checked) {
        this.batchTokens.push(tok)
      } else {
        const idx = findIndex(this.batchTokens, (t) => t.tokenId == tok.tokenId)
        this.batchTokens.splice(idx, 1)
      }
    },
    nextPage() {
      this.offset += this.limit
      if (this.offset >= this.myTokens.length) {
        this.offset -= this.limit
      }
    },
    prevPage() {
      let offset = this.offset - this.limit
      if (offset < 0) {
        this.offset = 0
      } else {
        this.offset = offset
      }
    },
    toggleHelp() {
      this.showHelp = false
      localStorage.setItem('showCollectionHelp', JSON.stringify(false))
    },
    getHasOneFullCard(card) {
      let hasFullCard = false
      if (card.cards?.length > 0) {
        card.cards.forEach((tactic) => {
          if (tactic.balance) {
            hasFullCard = true
          }
        })
      }
      return hasFullCard
    },
    async upgrade(card, recipe, times = 1) {
      this.nextGif = this.state.gifs[Math.floor(Math.random() * this.state.gifs.length)]
      this.upgrading = true
      card.upgrading = true
      this.state.loading = true
      if (this.isMuted === false) {
        this.$refs.audio.currentTime = 0
        this.$refs.audio.play()
      }
      try {
        let op = null

        const transactions = []

        const debatesReferrer = localStorage.getItem('debatesReferrer')
        if (debatesReferrer && this.refStatus.referredBy === false) {
          transactions.push({
            kind: OpKind.TRANSACTION,
            ...this.state.ppReferrer.methods.set_referred_by(debatesReferrer).toTransferParams(),
          })
        }

        const pixelOp = [
          {
            add_operator: {
              owner: this.state.userAddress,
              operator: process.env.VUE_APP_PP_CRAFTER,
              token_id: 0,
            },
          },
        ]
        transactions.push({
          kind: OpKind.TRANSACTION,
          ...this.state.ppPixel.methods.update_operators(pixelOp).toTransferParams(),
        })

        const operators = recipe.req_items.map((item) => {
          return {
            add_operator: {
              owner: this.state.userAddress,
              operator: process.env.VUE_APP_PP_CRAFTER,
              token_id: item.token_id,
            },
          }
        })
        transactions.push({
          kind: OpKind.TRANSACTION,
          ...this.state.ppMain.methods.update_operators(operators).toTransferParams(),
        })

        for (let i = 0; i < times; i++) {
          transactions.push({
            kind: OpKind.TRANSACTION,
            ...this.state.ppCrafter.methods.craft(recipe.id).toTransferParams(),
          })
        }

        const batch = await this.state.tezos.wallet.batch(transactions)
        op = await batch.send()
        await op.confirmation(1)

        this.state.loading = false
        this.upgradeCompleted = true

        // reduce local balances
        recipe.req_items.map((item) => {
          for (let i = 0; i < 3; i++) {
            if (card.fragments[i].tokenId === item.token_id) {
              card.fragments[i].balance -= item.amount * times
            }
            if (card.cards[i].tokenId === item.token_id) {
              card.cards[i].balance -= item.amount * times
            }
          }
          for (let i = 0; i < this.myTokens.length; i++) {
            if (this.myTokens[i].tokenId === item.token_id) {
              this.myTokens[i].balance -= item.amount * times
            }
          }
        })
        this.confirmation = true
        setTimeout(() => {
          this.confirmation = false
        }, 30000)

        // const usrName = this.state.userAlias
        //   ? this.state.userAlias
        //   : this.state.formattedAddr(this.state.userAddress)
        // const action = `${usrName} minted 1 ${token.upgradeTo}. :rocket:`
        // await axios({
        //   method: 'post',
        //   url: '/api/sendActivity',
        //   data: {
        //     content: action,
        //   },
        // })

        const upgradedTokenId = recipe.crafted_item.token_id
        const upgradedToken = find(this.state.allTokens, (tok) => {
          return tok.tokenId === upgradedTokenId
        })
        let idx = findIndex(this.myTokens, (mt) => mt.tokenId === upgradedToken.tokenId)
        if (idx > -1) {
          this.myTokens[idx].balance += recipe.crafted_item.amount * times
        } else {
          this.myTokens.push({ ...upgradedToken, balance: recipe.crafted_item.amount * times })
        }
        idx = findIndex(card.cards, (mt) => mt.tokenId === upgradedToken.tokenId)
        if (idx > -1) {
          card.cards[idx].balance += recipe.crafted_item.amount * times
        }

        setTimeout(() => {
          this.refresh()
        }, 5000)

        card.upgrading = false
        this.upgrading = false
        this.state.loadBalance()

        // this.$router.push(`/token/${upgradedTokenId}`)
      } catch (e) {
        card.upgrading = false
        this.upgrading = false
        this.state.loading = false
        this.$refs.audio.pause()
        console.error('Unable to upgrade card', e)
        if (e.name && e.name === 'BeaconWalletNotInitialized') {
          this.state.userAddress = null
          this.$router.push('/')
        }
      }
    },
    async upgradeBatch(tokens) {
      if (!Array.isArray(tokens)) {
        tokens = [tokens]
      }
      this.nextGif = this.state.gifs[Math.floor(Math.random() * this.state.gifs.length)]
      this.upgrading = true
      this.state.loading = true
      try {
        // opts.storageLimit = this.state.storageLimit
        const transactions = []
        for (let token of tokens) {
          const opts = {}
          opts.amount = token.upgradeCost
          opts.mutez = true
          token.upgrading = true
          let total = (token.balance - (token.balance % token.upgradeReq)) / token.upgradeReq
          if (total > 20) {
            total = 20
          }
          this.state.log('total', total)
          for (let i = 0; i < total; i++) {
            transactions.push({
              kind: OpKind.TRANSACTION,
              ...this.state.ppMain.methods.upgrade(token.tokenId).toTransferParams(),
              ...opts,
            })
          }
        }

        const batch = await this.state.tezos.wallet.batch(transactions)
        let op = await batch.send()
        await op.confirmation(1)

        // this.confirmation = true
        // setTimeout(()=>{this.confirmation = false}, 30000)

        for (let token of tokens) {
          let total = (token.balance - (token.balance % token.upgradeReq)) / token.upgradeReq
          if (total > 20) {
            total = 20
          }
          token.balance -= total * token.upgradeReq

          const usrName = this.state.userAlias
            ? this.state.userAlias
            : this.state.formattedAddr(this.state.userAddress)
          const action = `${usrName} burned ${total * token.upgradeReq} ${token.rarity} ${
            token.metadata.name
          }s and minted ${total} ${token.upgradeTo}s. :rocket:`
          await axios({
            method: 'post',
            url: '/api/sendActivity',
            data: {
              content: action,
            },
          })

          const upgradedTokenId = token.tokenId + 1000
          const upgradedToken = find(this.state.allTokens, (tok) => {
            return tok.tokenId === upgradedTokenId
          })
          let idx = findIndex(this.myTokens, (mt) => mt.tokenId === upgradedToken.tokenId)
          if (idx > -1) {
            this.myTokens[idx].balance += total
          } else {
            this.myTokens.push({ ...upgradedToken, balance: total, upgrading: false })
          }
          if (token.balance === 0) {
            idx = findIndex(this.myTokens, (mt) => {
              return mt.tokenId === token.tokenId
            })
            this.myTokens.splice(idx, 1)
          }

          // this.state.sortTokens(this.state.sortBy, true)
          // this.$forceUpdate()

          token.upgrading = false
          if (tokens.length == 1) {
            setTimeout(() => {
              this.state.loadMyTokens(true, true)
            }, 15000)
            this.upgrading = false
            this.$router.push(`/token/${upgradedTokenId}`)
          }
        }

        this.batchSelect = false
        this.batchTokens.length = 0

        setTimeout(() => {
          this.state.loadMyTokens(true, true)
          this.upgrading = false
          this.state.loading = false
        }, 15000)
      } catch (e) {
        for (let token of tokens) {
          token.upgrading = false
        }
        this.batchSelect = false
        this.batchTokens.length = 0
        this.upgrading = false
        this.state.loading = false
        console.error('Unable to upgrade tokens', e)
        if (e.name && e.name === 'BeaconWalletNotInitialized') {
          this.state.userAddress = null
          this.$router.push('/')
        }
      }
    },
    async getMyStats() {
      const resp = await axios({
        url: '/api/myStats',
        params: {
          owner: this.state.userAddress,
        },
      })
      this.state.myStats = resp.data.myStats

      const rankResp = await axios({
        url: '/api/myRank',
        params: {
          orderBy: 'weighted',
          owner: this.state.userAddress,
        },
      })
      this.state.myStats.rank = rankResp.data.rank
    },
    async setAlias() {
      try {
        if (this.state.userAddress === null) {
          return
        }
        this.aliasInput = this.aliasInput.trim()
        if (this.aliasInput.length > this.aliaserMaxLen) {
          alert(
            'Max alias length is 15 chars. If you feel this is too restrictive, please reach out to an admin on discord.'
          )
          return
        }
        if (this.aliasInput.length < 3) {
          alert(
            'Min alias length is 3 chars. If you feel this is too restrictive, please reach out to an admin on discord.'
          )
          return
        }
        this.settingAlias = true

        const aliaser = await this.state.tezos.wallet.at(process.env.VUE_APP_PP_ALIASER)

        const opts = {}
        opts.amount = this.aliaserCost
        opts.mutez = true
        let op = await aliaser.methods.set_alias(this.aliasInput).send(opts)
        await op.confirmation(1)

        this.state.userAlias = this.aliasInput
        this.settingAlias = false
        this.showAliasInput = false

        await axios({
          method: 'post',
          url: '/api/addAlias',
          data: {
            userAddress: this.state.userAddress,
            userAlias: this.state.userAlias,
          },
        })

        await axios({
          method: 'post',
          url: '/api/sendActivity',
          data: {
            content: `${this.state.formattedAddr(this.state.userAddress)} is now known as '${
              this.aliasInput
            }' :detective:`,
          },
        }).catch(console.error) // do not display this to user
      } catch (e) {
        this.settingAlias = false
        console.error('Unable to connect wallet', e)
        let message = e.message
          ? `Error updating alias: ${e.message}`
          : `Error updating alias: Please try again or contact support.`
        alert(message)
      }
    },
  },
}
</script>
