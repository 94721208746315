<template>
  <div>
    <div
      class="
        crest
        flex flex-col
        items-center
        justify-center
        text-white
        md:pt-0
        lg:pt-2
        xl:pt-4
        hidden
        md:inline-block
      "
    >
      <p v-if="!state.userAddress" class="text-xs pt-2">
        🇺🇸🇺🇸🇺🇸🇺🇸<a href="/#connectWallet" class="text-white hover:text-blue-link cursor-pointer">
          Connect Wallet </a
        >🇺🇸🇺🇸🇺🇸🇺🇸
      </p>
      <p v-if="state.userAddress" class="text-xs breakwords">
        <AddrIcon
          :address="state.userAddress"
          :alias="state.userAlias"
          :show-disconnect="true"
          :is-pill="false"
          :link-to-collection="true"
        />
        {{ state.convertFromMutez(state.pxlBalance) }} $PXL
        <a
          href="https://quipuswap.com/swap?from=tez&to=KT1F1mn2jbqQCJcsNgYKVAQjvenecNMY2oPK_0"
          target="_blank"
        >
          <fa icon="plus-square" class="align-middle" />
        </a>
      </p>
    </div>
    <div class="flex flex-row items-center justify-center pt-5">
      <div>
        <router-link to="/">
          <img
            src="../assets/eagle-right.png"
            :class="{ 'animate-pulse': state.loading }"
            width="100"
          />
        </router-link>
      </div>
      <div class="col-span-4 flex flex-col items-center">
        <h1 class="text-2xl md:text-4xl lg:text-5xl my-0 max-w-sm md:max-w-lg lg:max-w-2xl">
          <img src="../assets/DebatesLogo.png" :class="{ 'animate-pulse': state.loading }" />
        </h1>
      </div>
      <div>
        <router-link to="/">
          <img
            class="float-right"
            :class="{ 'animate-pulse': state.loading }"
            src="../assets/eagle.png"
            width="100"
          />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import AddrIcon from './AddrIcon.vue'

export default {
  name: 'Crest',
  components: {
    AddrIcon,
  },
  props: {
    state: {
      type: Object,
      default: () => {
        return {}
      },
    },
    connectWallet: {
      type: Function,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      state_root: this.$root.$data.state,
      timer: '',
    }
  },
  computed: {
    getBalance() {
      return this.state_root.convertFromMutez(this.state_root.treasureBalance)
    },
    getNextPayout() {
      return this.state_root.convertFromMutez(
        this.state_root.treasureBalance * (this.state_root.treasurePayoutPercent / 100)
      )
    },
  },
  created() {
    this.state_root.getClaimStorage()
    this.state_root.getUsersCount()
    setInterval(() => {
      this.state_root.getClaimStorage()
      this.state_root.getUsersCount()
    }, 120000)
  },
  methods: {
    async disconnect() {
      try {
        await this.state.disconnect()
        this.$router.push('/')
      } catch (e) {
        console.error('Unable to disconnect wallet', e)
      }
    },
  },
}
</script>

<style>
.treasure {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.treasure > span {
  font-size: 10px !important;
}

@media (min-width: 40em) {
  .treasure {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
</style>
