<template>
  <div class="container mx-auto text-center pb-12 px-4 font-px h-screen">
    <burger-menu
      :is-open="menu"
      :toggle-open="
        () => {
          menu = !menu
        }
      "
      class="absolute top-0 left-0 mt-2 block md:hidden w-full"
    />
    <Header :connect-wallet="connectWallet" />
    <main class="flex-1 text-center overflow-y-auto">
      <router-view v-slot="{ Component }">
        <transition>
          <div>
            <component :is="Component" :connect-wallet="connectWallet" />
          </div>
        </transition>
      </router-view>
      <History
        :is-open="state.history.isOpen"
        :toggle-open="
          () => {
            state.history.isOpen = !state.history.isOpen
          }
        "
      />
    </main>
    <Footer :connect-wallet="connectWallet" />
  </div>
</template>

<script>
import { BeaconEvent, defaultEventCallbacks } from '@airgap/beacon-sdk'
import { BeaconWallet } from '@taquito/beacon-wallet'
import { compose, TezosToolkit } from '@taquito/taquito'
import { tzip12, Tzip12Module } from '@taquito/tzip12'
import { tzip16, Tzip16Module } from '@taquito/tzip16'
import axios from 'axios'
import { find, findIndex, forEach, groupBy } from 'lodash'
import { isMobile } from 'mobile-device-detect'
import moment from 'moment'

import washington_champ from '@/assets/common/01George.png'
import jefferson from '@/assets/common/03Jefferson.png'
import jackson from '@/assets/common/07Jackson.png'
import polk from '@/assets/common/11Polk.png'
import pierce from '@/assets/common/14Pierce.png'
import andrewjohnson from '@/assets/common/17Johnson.png'
import grant from '@/assets/common/18Grant.png'
import hayes from '@/assets/common/19Hayes.png'
import arthur from '@/assets/common/21Arthur.png'
import roosevelt from '@/assets/common/26Roosevelt.png'
import wilson from '@/assets/common/28Wilson.png'
import truman from '@/assets/common/33Truman.png'
import kennedy from '@/assets/common/35Kennedy.png'
import nixon from '@/assets/common/37Nixon.png'
import ghwbush from '@/assets/common/41GeorgeHWBush.png'
import clinton from '@/assets/common/42Clinton.png'
import obama from '@/assets/common/44Obama.png'
import trump from '@/assets/common/45Trump.png'
import eagleRight from '@/assets/eagle-right.png'
import greatseal from '@/assets/greatseal-pixel.png'
import washington from '@/assets/logo-head.png'
import partypotus from '@/assets/partypotus.gif'

import BurgerMenu from './components/BurgerMenu.vue'
import Footer from './Footer'
import Header from './Header'
import History from './views/History'

const store = {
  state: {
    usersCount: 0,
    maxClaimsPerPeriod: 0,
    totalClaims: 0,
    untilNextPeriod: moment(),
    periodStart: new Date().toISOString(),
    periodLen: 300,
    contract: process.env.VUE_APP_PP_MAIN,
    debug: false, //process.env.VUE_APP_DEBUG,
    isMobile: isMobile,
    tezos: null,
    ppMain: null,
    ppMarket: null,
    ppAuction: null,
    ppClaimer: null,
    ppCrafter: null,
    ppPixel: null,
    ppReferrer: null,
    userAddress: null,
    userAlias: null,
    balance: 0,
    pxlBalance: 0,
    pxlMod: 1000,
    wallet: null,
    loading: false,
    allTokens: [],
    stats: null,
    statsTimestamp: null,
    myStats: null,
    myTokens: [],
    myTokensLoaded: false,
    myTokensLoading: true,
    myTokensTimestamp: null,
    sortBy: 'tokenId',
    sortDir: true,
    refreshTimeout: 15000,
    seriesMeta: null,
    tradingFee: 100 / 1000,
    storageLimit: process.env.VUE_APP_STORAGE_LIMIT,
    geoLoaded: false,
    canParticipate: true,
    agreedToDisclaimer: false,
    termsVersion: '202110290648',
    aliases: [],
    history: {
      items: [],
      limit: 25,
      offset: 0,
      isOpen: false,
    },
    images: {
      eagleRight,
      grant,
      pierce,
      jackson,
      trump,
      jefferson,
      wilson,
      greatseal,
      kennedy,
      clinton,
      nixon,
      obama,
      arthur,
      hayes,
      partypotus,
      washington,
    },
    gifs: [
      'https://media.giphy.com/media/uR6JMOFCtLmXkcKOkG/giphy.gif',
      'https://media.giphy.com/media/3o7qDSOvfaCO9b3MlO/giphy.gif',
      'https://media.giphy.com/media/5xaOcLISUrBchel7P2w/giphy.gif',
      'https://media.giphy.com/media/l0HlzECw20Y4dK20o/giphy.gif',
      'https://media.giphy.com/media/l3q2UHBacvdiTFada/giphy.gif',
      'https://media.giphy.com/media/xUPGcnhop8QD7HHztS/giphy.gif',
      'https://media.giphy.com/media/ZWQoadIl0Dpks/giphy.gif',
      'https://media.giphy.com/media/HyuDpwNGHporNEQlK8/giphy.gif',
      'https://media.giphy.com/media/BMXOmkI9ocLntJs5XJ/giphy.gif',
      'https://media.giphy.com/media/lT4N7JiPGATIhVwR91/giphy.gif',
      'https://media.giphy.com/media/3oFzm10eM9MbffB8Kk/giphy.gif',
      'https://media.giphy.com/media/I0SZc5Ok4FNQI/giphy.gif',
      'https://media.giphy.com/media/6w4k5LkiIcsko/giphy.gif',
      'https://media.giphy.com/media/xTiTnpxf2eJs1ah4ac/giphy.gif',
      'https://media.giphy.com/media/7JI6mrDzbBHJcROiCZ/giphy.gif',
      'https://media.giphy.com/media/6BXy9tYDuxUru/giphy.gif',
      'https://media.giphy.com/media/1TSUKOv4k56aIryKAP/giphy.gif',
      'https://media.giphy.com/media/tkQ8MFJYh0hyM/giphy.gif',
      'https://media.giphy.com/media/4JqMQ4Ei49pN6/giphy.gif',
      'https://media.giphy.com/media/QW8Ar7eDciGBi/giphy.gif',
      'https://media.giphy.com/media/2QEpmEqRyncDGF75Ck/giphy.gif',
      'https://media.giphy.com/media/3daKRVqDKvfK2JyrKl/giphy.gif',
      'https://media.giphy.com/media/pHXeZqje25rVlFkIsC/giphy.gif',
      'https://media.giphy.com/media/oy3mWIOFPTu1VIPiD5/giphy.gif',
      'https://media.giphy.com/media/fZYpwfWWg9F9egQhdS/giphy.gif',
      'https://media.giphy.com/media/jbhzksjOIFWjPY7ZOW/giphy.gif',
      'https://media.giphy.com/media/eKsd2RNOX9m5t0ocfj/giphy.gif',
      'https://media.giphy.com/media/1xkbNpMe3JE1XilDMN/giphy.gif',
      'https://media.giphy.com/media/l4q8c9XJzuMPomcTe/giphy.gif',
      'https://media.giphy.com/media/62aJnbbjvkW9ZXU669/giphy.gif',
      'https://media.giphy.com/media/tEIlEVkgghDMJQ5MyR/giphy.gif',
      'https://media.giphy.com/media/31R8mUy9mGkE6Bn8vl/giphy.gif',
      'https://media.giphy.com/media/Sd8uqMJqpGpP2/giphy.gif',
      'https://media.giphy.com/media/xT39DfLQ4ZauMUYzKM/giphy.gif',
      'https://media.giphy.com/media/UX4Wx38jJjbrZ2GKDt/giphy.gif',
      'https://media.giphy.com/media/3ohs83uxKupZIGAK2c/giphy.gif',
      'https://media.giphy.com/media/3F4T2OKB8ZXYBLVztQ/giphy.gif',
      'https://media.giphy.com/media/1lxVhK3EvVQP0POL72/giphy.gif',
      'https://media.giphy.com/media/SWV4S6i79pygM/giphy.gif',
    ],
    champions: [
      {
        name: 'George Washington',
        gf: 1,
        bf: 1,
        abilities: `Rockstar: The Crowdstate always buffs your tactics and it's double (2x Buff).`,
        fp: 40,
        img: washington_champ,
      },
      {
        name: 'Andrew Jackson',
        gf: 0,
        bf: 0,
        abilities: `Rough and Ready: You may distribute 4 "duel counters" per round. Counters on your arguments give +1 Str, 
        counters on opponent's arguments give -1 Str.`,
        fp: 35,
        img: jackson,
      },
      {
        name: 'James K. Polk (Coming Soon)',
        gf: 0,
        bf: 0,
        abilities: `Aggressive Expansion: Take control of an opponent's free (no RD) argument, or an RD.  
        You can only control a tactic if you have a legal thread to play it on.  Use once per round.`,
        fp: 35,
        img: polk,
      },
      {
        name: 'Andrew Johnson (Coming Soon)',
        gf: 1,
        bf: 1,
        abilities: `Reconstruction: If an opponent targets you, your hand, or your board, 
        immediately draw a card and gain 2 Fortitude. Congressional Veto: You cannot target your opponent, 
        your opponents hand, or your opponent's board.`,
        fp: 35,
        img: andrewjohnson,
      },
      {
        name: 'Theodore Roosevelt',
        gf: 1,
        bf: 0,
        abilities:
          'The Mad Messiah: At the beginning of every round, gain 1 Fortitude, shift crowd 1 Enlightened, and draw 1 card. Trust Buster: Players cannot play more than 1 RD on each argument.',
        fp: 35,
        img: roosevelt,
      },
      {
        name: 'Woodrow Wilson',
        gf: 0,
        bf: 0,
        abilities:
          'Death and Taxes: When opponents lose fortitude from costs at Round End you gain 1/2 that much Fortitude (rounded down).',
        fp: 35,
        img: wilson,
      },
      {
        name: 'Harry S. Truman',
        gf: 1,
        bf: 1,
        abilities: `Drop the Bomb: Pick an argument in your hand and discard it to deal its Strength to an opponent while you lose its cost. Use once per round.`,
        fp: 35,
        img: truman,
      },
      {
        name: 'George H. W. Bush',
        gf: 0,
        bf: 1,
        abilities: `Wouldn't be Prudent: Target opponent's thread gets -2 Strength. Use once per round. Read my Lips: Opponent's arguments cost an additional 1 Fortitude. Use once per match.`,
        fp: 35,
        img: ghwbush,
      },
      {
        name: 'Barack Obama',
        gf: 0,
        bf: 1,
        abilities: 'Polished Delivery: RDs you play get +1 Strength.',
        fp: 35,
        img: obama,
      },
    ],
    formattedAddr(address) {
      return 'ꜩ' + address.substring(0, 5) + '..' + address.substring(address.length - 5)
    },
    formattedContract(address) {
      if (!address || address.length == 0) {
        return ''
      }
      return address.substring(0, 5) + '..' + address.substring(address.length - 5)
    },
    convertFromMutez: (amt, fixed = 3) => {
      return (amt / 1000000).toFixed(fixed)
    },
    convertToMutez: (amt) => {
      return amt * 1000000
    },
    async getClaimStorage() {
      const claimResp = await axios({
        url: '/api/claimMeta',
      })

      this.periodStart = claimResp.data.meta.periodStart
      this.periodLen = claimResp.data.meta.periodLen
      this.maxClaimsPerPeriod = claimResp.data.meta.maxClaimsPerPeriod
      this.untilNextPeriod = moment(this.periodStart).add(this.periodLen, 'seconds')
      const isNextPeriod = moment(this.periodStart)
        .add(this.periodLen, 'seconds')
        .isBefore(moment())

      if (!isNextPeriod) {
        this.totalClaims = claimResp.data.meta.totalClaims
      } else {
        this.totalClaims = 0
        this.untilNextPeriod = moment().add(this.periodLen, 'seconds')
      }
    },
    async getUsersCount() {
      const resp = await axios({
        url: '/api/getUsersCount',
      })
      this.usersCount = resp.data.myStats.count
    },
    async getPxlMod() {
      const resp = await axios({
        url: '/api/pxlMod',
      })
      this.pxlMod = resp.data.pxlMod
    },
    getRarity: (rarity) => {
      switch (rarity) {
        case 1000:
          return 'common'
        case 2000:
          return 'uncommon'
        case 3000:
          return 'rare'
        case 4000:
          return 'epic'
        case 5000:
          return 'legendary'
        case 6000:
          return 'unique'
      }
    },
    sortTokens(sortBy, ignore = false) {
      if (this.sortBy == sortBy && !ignore) {
        this.sortDir = !this.sortDir
      }
      this.sortBy = sortBy

      this.myTokens.sort((a, b) => {
        switch (sortBy) {
          case 'name':
            if (this.sortDir) return ('' + a.metadata.name).localeCompare(b.metadata.name)
            return ('' + b.metadata.name).localeCompare(a.metadata.name)
          case 'rarity':
            if (this.sortDir) return a.tokenId - b.tokenId
            return b.tokenId - a.tokenId
          case 'balance':
            if (this.sortDir) return a.balance - b.balance
            return b.balance - a.balance
          default:
            if (this.sortDir) return (a.tokenId % 1000) - (b.tokenId % 1000)
            return (b.tokenId % 1000) - (a.tokenId % 1000)
        }
      })
    },
    log(...msg) {
      if (this.debug) {
        console.log(...msg)
      }
    },
    async disconnect() {
      await this.wallet.client.clearActiveAccount()
      this.userAddress = null
      this.myTokens = []
      this.myTokensLoaded = false
      localStorage.removeItem('myTokens')
      this.userAlias = null
      localStorage.removeItem('myAlias')
      this.agreedToDisclaimer = false
      this.balance = 0
      this.pxlBalance = 0
    },
    async loadBalance() {
      const bal = await this.tezos.tz.getBalance(this.userAddress)
      this.balance = bal.toNumber()

      const pxlResp = await axios('/api/pxlBalance?userAddress=' + this.userAddress)
      this.pxlBalance = pxlResp.data.balance
    },
    async loadStats(force = false) {
      let lastLoad = moment()
      const ls = localStorage.getItem('stats')
      if (ls) {
        const parsedStats = JSON.parse(ls)
        this.stats = parsedStats.stats
        lastLoad = moment(parsedStats.timestamp)
        this.statsTimestamp = lastLoad
      }
      if (this.stats === null || lastLoad.isBefore(moment().subtract(1, 'hour')) || force) {
        const statsResp = await axios({
          url: '/api/stats',
        })

        const allCards = groupBy(this.allTokens, 'seriesId')
        let series = []
        forEach(allCards, (tokens, seriesId) => {
          let title = tokens[0].metadata.name.split(' - ')[0]
          for (let i = 0; i < tokens.length; i++) {
            const tokStat = find(statsResp.data.stats, (ts) => ts.tokenId == tokens[i].tokenId)
            if (tokStat) {
              tokens[i].supply = tokStat.supply
              tokens[i].burned = tokStat.burned
            }
          }
          series.push({
            seriesId,
            title,
            strength: tokens[0].metadata.strength,
            cost: tokens[0].metadata.cost,
            effect: tokens[0].metadata.effect,
            alignment: tokens[0].metadata.alignment,
            displayUri: tokens[0].metadata.displayUri,
            tokens: tokens.sort((a, b) => {
              return a.tokenId - b.tokenId
            }),
          })
        })
        series.sort((a, b) => {
          return a.title.localeCompare(b.title)
        })
        this.stats = {
          series,
        }
        localStorage.setItem(
          'stats',
          JSON.stringify({ stats: this.stats, timestamp: new Date().toISOString() })
        )
        this.statsTimestamp = moment()
      }
    },
    async loadAllTokens(force = false) {
      const allTokens = localStorage.getItem('allTokens')
      if (allTokens) {
        this.allTokens = JSON.parse(allTokens).tokens
      }
      if (force) {
        const tokResp = await axios({
          url: '/api/allTokens',
        })
        this.allTokens = tokResp.data.tokens.sort((a, b) => {
          const res2 = ('' + a.metadata.name.split(' - ')[0]).localeCompare(
            b.metadata.name.split(' - ')[0]
          )
          return res2 === 0 ? a.tokenId - b.tokenId : res2
        })
        localStorage.setItem('allTokens', JSON.stringify({ tokens: this.allTokens }))
      }
    },
    async loadAllRecipes() {
      const recResp = await axios({
        url: '/api/allRecipes',
      })
      this.allRecipes = recResp.data.recipes.map((r) => {
        const crafted_token = find(this.allTokens, (t) => t.tokenId === r.crafted_item.token_id)
        r.crafted_item.token = crafted_token
        for (let i = 0; i < r.req_items.length; i++) {
          const req_token = find(this.allTokens, (t) => t.tokenId === r.req_items[i].token_id)
          delete req_token.recipe
          r.req_items[i].token = req_token
        }
        return r
      })
    },
    async loadMyTokens(force = false, skipCache = false) {
      const myTokens = localStorage.getItem('myTokens')
      if (myTokens && !skipCache) {
        const mt = JSON.parse(myTokens)
        if (mt.timestamp && moment(mt.timestamp).isAfter(moment().subtract(30, 'minutes'))) {
          this.myTokens.length = 0
          this.myTokens.push(...mt.tokens)
          this.myTokensLoaded = true
          this.myTokensTimestamp = moment(mt.timestamp)
        }
      }

      if (!this.userAddress) {
        return
      }

      this.loadBalance()

      if (!this.myTokensLoaded || force) {
        this.myTokensLoading = true
        const tokResp = await axios({
          method: 'post',
          url: '/api/myTokens',
          data: {
            allTokens: this.allTokens,
            userAddress: this.userAddress,
          },
        })

        tokResp.data.tokens.forEach((tok) => {
          const idx = findIndex(this.myTokens, (mt) => mt.tokenId === tok.tokenId)
          if (idx > -1) {
            this.myTokens[idx].balance = tok.balance
          } else {
            this.myTokens.push({ ...tok, upgrading: false })
          }
        })

        for (var i = 0; i < this.myTokens.length; i++) {
          const idx = findIndex(tokResp.data.tokens, (tok2) => {
            return tok2.tokenId === this.myTokens[i].tokenId
          })
          if (idx === -1) {
            this.myTokens.splice(i, 1)
            i--
          }
        }
        this.sortTokens(this.sortBy, true)
        this.myTokensLoaded = true
        this.myTokensTimestamp = moment()
        this.myTokensLoading = false

        localStorage.setItem(
          'myTokens',
          JSON.stringify({
            tokens: this.myTokens.map((t) => {
              delete t.upgrading
              return t
            }),
            timestamp: this.myTokensTimestamp.toISOString(),
          })
        )
      }
    },
    async loadMyAlias() {
      const myAlias = localStorage.getItem('myAlias')
      if (myAlias) {
        this.userAlias = JSON.parse(myAlias).alias
      }

      const alias = find(this.aliases, (a) => {
        return a.address === this.userAddress
      })
      if (alias) {
        this.userAlias = alias.alias
        localStorage.setItem('myAlias', JSON.stringify({ alias: this.userAlias }))
      }
    },
    async saveDisclaimer() {
      this.agreedToDisclaimer = true
      localStorage.setItem('disclaimer', true)
    },
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'robots',
          content: process.env.VUE_APP_ENV === 'production' ? 'index, follow' : 'noindex, nofollow',
        },
      ],
    }
  },
}

export default {
  name: 'App',
  components: {
    BurgerMenu,
    Header,
    Footer,
    History,
  },
  data() {
    return {
      state: store.state,
      menu: false,
    }
  },
  async mounted() {
    try {
      this.state.log(`Connecting to tezos at ${process.env.VUE_APP_TEZOS_RPC}`)
      this.state.tezos = new TezosToolkit(process.env.VUE_APP_TEZOS_RPC)
      this.state.tezos.addExtension(new Tzip16Module())
      this.state.tezos.addExtension(new Tzip12Module())

      const options = {
        name: 'PixelDebates',
        iconUrl: 'https://www.pixeldebates.com/logo-white.png',
        preferredNetwork: process.env.VUE_APP_TEZOS_NETWORK,
        eventHandlers: {
          [BeaconEvent.PAIR_INIT]: {
            handler: defaultEventCallbacks.PAIR_INIT,
          },
          [BeaconEvent.PAIR_SUCCESS]: {
            handler: defaultEventCallbacks.PAIR_SUCCESS,
          },
        },
      }
      this.state.wallet = new BeaconWallet(options)
      const activeAccount = await this.state.wallet.client.getActiveAccount()
      this.state.userAddress = activeAccount ? await this.state.wallet.getPKH() : null

      await this.getGeo()
      await this.state.loadAllTokens(true)
      this.state.loadAllRecipes()
      this.state.getPxlMod()

      await this.getAliases()
      this.state.loadStats()

      if (this.state.userAddress) {
        this.state.tezos.setProvider({ wallet: this.state.wallet })
        this.state.ppMain = await this.state.tezos.wallet.at(
          process.env.VUE_APP_PP_MAIN,
          compose(tzip16, tzip12)
        )
        this.state.ppMarket = await this.state.tezos.wallet.at(
          process.env.VUE_APP_PP_MARKET,
          tzip16
        )
        // this.state.ppAuction = await this.state.tezos.wallet.at(process.env.VUE_APP_PP_AUCTION, tzip16)
        this.state.ppClaimer = await this.state.tezos.wallet.at(
          process.env.VUE_APP_PP_CLAIMER,
          tzip16
        )
        this.state.ppCrafter = await this.state.tezos.wallet.at(
          process.env.VUE_APP_PP_CRAFTER,
          tzip16
        )
        this.state.ppPixel = await this.state.tezos.wallet.at(process.env.VUE_APP_PP_PIXEL, tzip16)
        this.state.ppReferrer = await this.state.tezos.wallet.at(
          process.env.VUE_APP_PP_REFERRER,
          tzip16
        )
        this.state.loadMyTokens(true)
        this.state.loadMyAlias()
      }
    } catch (e) {
      console.error('Unable to init taquito', e)
      // await new Promise((resolve) => setTimeout(resolve, 15000))
      // this.$router.go(0)
    }
  },
  methods: {
    async connectWallet() {
      try {
        await this.state.wallet.requestPermissions({
          network: {
            type: process.env.VUE_APP_TEZOS_NETWORK,
          },
        })
        this.state.userAddress = await this.state.wallet.getPKH()
        this.state.tezos.setProvider({ wallet: this.state.wallet })

        this.state.loadMyAlias()

        this.state.ppMain = await this.state.tezos.wallet.at(
          process.env.VUE_APP_PP_MAIN,
          compose(tzip16, tzip12)
        )
        this.state.ppMarket = await this.state.tezos.wallet.at(
          process.env.VUE_APP_PP_MARKET,
          tzip16
        )
        this.state.ppClaimer = await this.state.tezos.wallet.at(
          process.env.VUE_APP_PP_CLAIMER,
          tzip16
        )
        this.state.ppCrafter = await this.state.tezos.wallet.at(
          process.env.VUE_APP_PP_CRAFTER,
          tzip16
        )
        this.state.ppPixel = await this.state.tezos.wallet.at(process.env.VUE_APP_PP_PIXEL, tzip16)
        this.state.ppReferrer = await this.state.tezos.wallet.at(
          process.env.VUE_APP_PP_REFERRER,
          tzip16
        )
        this.state.loadMyTokens(true)

        await axios({
          method: 'post',
          url: '/api/termsLog',
          data: {
            userAddress: this.state.userAddress,
            version: this.state.termsVersion,
            application: 'PixelDebates',
          },
        })

        await axios({
          method: 'post',
          url: '/api/sendActivity',
          data: {
            content: `${this.state.formattedAddr(
              this.state.userAddress
            )} connected their wallet and became a contender! :flag_us:`,
          },
        })
      } catch (e) {
        console.error('Unable to connect wallet', e)
      }
    },
    async getSeriesMeta() {
      const resp = await axios({
        url: '/api/seriesMeta',
      })
      this.state.seriesMeta = resp.data.meta
      this.state.log('seriesMeta', this.state.seriesMeta)
    },
    async getAliases() {
      const resp = await axios('/api/aliases')
      this.state.aliases = resp.data.aliases
      this.state.log('aliases', this.state.aliases)
    },
    async getGeo() {
      const geoResp = await axios({
        url: '/api/geoip',
      })
      let country = geoResp?.data?.geo?.country
      const sanctions = [
        'IR',
        'IRN',
        'CU',
        'CUB',
        'SY',
        'SYR',
        'SD',
        'SDN',
        'SS',
        'SSD',
        'KP',
        'PRK',
        'UA',
        'UKR',
      ]
      this.state.canParticipate = sanctions.indexOf(country) === -1
      this.state.geoLoaded = true
    },
  },
}
</script>

<style src="@vueform/slider/themes/default.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style>
/* .crest {
  background-image: url('assets/frame_top.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 16rem;
} */

body {
  background-color: #e9f1e6;
  background-image: url('assets/frame_top.png');
  background-size: contain;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
}

.speechbg {
  background-size: 100% 100%;
  background-image: url('assets/tall-speech-bub.png');
  padding-left: 2.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: white;
}
.speechbg-rev {
  background-image: url('assets/tall-speech-bub-right.png');
  background-size: 100% 100%;
  padding-right: 2.5rem;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: white;
}
@screen sm {
  .speechbg {
    background-size: 100% 100%;
    background-image: url('assets/tall-speech-bub.png');
    padding-left: 3rem;
    padding-right: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: white;
  }
  .speechbg-rev {
    background-image: url('assets/tall-speech-bub-right.png');
    background-size: 100% 100%;
    padding-right: 3rem;
    padding-left: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: white;
  }
}
@screen md {
  .speechbg {
    background-size: 100% 100%;
    background-image: url('assets/bubble.png');
    padding-left: 8rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    color: white;
  }
  .speechbg-rev {
    background-image: url('assets/bubble-right.png');
    background-size: 100% 100%;
    padding-right: 8rem;
    padding-left: 4rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    color: white;
  }
}

.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: 12px;
}
.multiselect__tag,
.multiselect__option--highlight {
  background: #006bb3;
}
.multiselect__option--selected.multiselect__option--highlight {
  background: #006bb3;
}
.multiselect__tag-icon:after {
  color: rgb(194, 194, 194);
  /* background: #006bb3; */
}

.slider-blue {
  --slider-connect-bg: #006bb3;
  --slider-tooltip-bg: #006bb3;
  --slider-handle-ring-color: #3b82f630;
}

pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  font-family: 'Times New Roman', Times, serif;
}
</style>
