<template>
  <div class="grid grid-cols-1 items-left justify-between mt-12 mb-12">
    <h2 class="text-2xl pb-5">Terms and Conditions</h2>
    <div class="text-left w-full lg:w-2/3 m-auto">
      <pre>
1. Acceptance of Terms
These Terms and Conditions (these “Terms”) form a legal agreement between you and Pixel Global, Ltd. and all of its respective subsidiaries, affiliates, and contractors, (together and hereafter the “Company”). By using any of the Company’s services, content, or site, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree with these Terms, do not use any of the Company’s services, content, or site.

2. Eligibility
You must be of the age of majority in your jurisdiction of residence or older to be eligible to use the site or request any services. By using the site, you represent and warrant that you (a) are of the age of majority in your jurisdiction of residence or older, (b) have the right, authority, and capacity to agree to and abide by these Terms, and (c) have not been previously banned or removed from the site or will participate in any activity that may result in a ban or removal from the site.

3. Access and Use of the Company’s Services and Content
Subject to your compliance with these Terms, the Company grants you access to the site and use of any services offered only for lawful purposes. The site, services, and all content and other materials available through the Company are proprietary property of the Company and are protected by British Virgin Islands and international copyright, patent, and trademark laws. 

You shall not (a) sell, resell, transfer, or use commercially any of the services or content, (b) distribute or communicate (including by telecommunication), publicly display, or publicly perform any content, (c) alter or make any derivative uses of the content or any portion thereof, (d) modify, adapt, or hack the site, or otherwise attempt to gain unauthorized access to the Company related systems or networks, (e) use the content other than for their intended purposes, or (f) use any data mining, screen scraping, or similar data collection or extraction methods. Any use of the content other than as explicitly approved herein, without receiving the Company’s written permission, is strictly prohibited and will automatically terminate your authorized use of the site and any offered services. Nothing in these Terms shall be interpreted as conferring any license or right to any copyright, patent, trademark, or other proprietary rights of the Company or any third party. 

4. Registration and Account Standards
By creating an account, signing up to the waiting list, or for any informational material, you agree to (a) give accurate, current, and complete information about yourself and (b) maintain your account information and update it when necessary.

5. Permission to Contact You
By providing us with your information, you agree to allow the Company to contact you via the site, text, SMS, social media, and email as applicable.

6. Lawful Basis for Collecting and Processing Data
The Company must collect, use, store, and disclose your personal information in order to provide the services offered. 

If you request information or services from the Company, the following personal information will be collected: 
Your web browser information (type, version)
Your wallet address (when connected to a wallet),
Your IP address and your geographic location.

Personal information requirements may change at any time. The Company will give you appropriate notice when information shall be updated, or new information will be required to remain in the Company system. By providing the Company with updated personal information, you explicitly provide your consent for the Company to use that information to provide you with the information and any offered services.

The Company will disclose personal information collected to third parties in order to provide information and services you have requested. The Company has contracts with third-party processors that forbid them from using or disclosing personal information except as necessary to execute the services you may request or to fulfil legal obligations.

By using the services, you acknowledge that you have read, understood, and agree that the Company must collect, use, store, and disclose your personal information in order to provide the information and any offered services. 

7. Individual Data Rights and User Privacy
Please refer to the Company’s Privacy Policy to understand how the Company collects, uses, and discloses user data and protects user privacy.

8. User Rules of Behaviour
As a condition of use of the site, you unconditionally agree to abide by these User Rules of Behaviour and not use the site for any purpose that is prohibited. You shall not:
· Take action that is unlawful, threatening, harassing, deceptive, fraudulent, invasive of another’s privacy, or is otherwise inappropriate as determined by the Company in its sole discretion
· Provide false or misleading information
· Use or endeavour to use another’s personal information
· Impersonate any person or entity
· Harvest, data mine, or attempt to collect any personal information about others, including email
· Use the information or any offered services for the purposes of infringing on privacy rights, property rights, or any other kind of rights of any person, company, or institution
· Use the content or any offered services for any commercial purposes, including but not limited to, advertising, distribution, promotion, including chain letters, junk email, or repetitive message to anyone
· Distribute, promote, or otherwise publish any kind of malicious code, viruses of any kind, or any other item or communication that may cause harm to the site or to another user’s system in any way
· Reverse-engineer any aspect of the site or do anything that might discover source code or bypass or circumvent measures employed to prevent or limit access to any content
· Attempt to circumvent any content-filtering techniques the site employs or attempt to access any feature or area of the site that you are not authorized to access
· Use any sort of automated means or interface not authorized to access the site, extract data, or otherwise interfere with or modify the rendering of site pages or functionality

9. Disclaimer of Warranties
THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY WARRANTIES OF ANY KIND, AND THE COMPANY EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF ACCURACY, MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. YOU ACKNOWLEDGE THAT THE COMPANY DOES NOT WARRANT THAT THE INFORMATION OR ANY OFFERED SERVICES WILL MEET YOUR REQUIREMENTS, WILL BE UNINTERRUPTED, TIMELY, SECURE, ERROR-FREE, OR VIRUS-FREE, THAT THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE INFORMATION OR ANY OFFERED SERVICE WILL BE ACCURATE OR RELIABLE, OR THAT ANY ERRORS IN THE CONTENT OR ANY OFFERED SERVICES WILL BE CORRECTED.

10.Limitation of Liability
UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (WHETHER IN CONTRACT, TORT, STRICT PRODUCT LIABILITY, OR OTHERWISE) SHALL THE COMPANY AND ITS PARENTS, SUBSIDIARIES, AFFILIATES, CONTRACTORS, LICENSORS, OWNERS, AGENTS, INFORMATION PROVIDERS, LICENSEES, DIRECTORS, OFFICERS, AND EMPLOYEES (COLLECTIVELY, THE “COMPANY PARTIES”) BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, CONSEQUENTIAL, OR PUNITIVE DAMAGES OF ANY KIND, INCLUDING BUT NOT LIMITED TO, LOSS OF INCOME OR PROFITS, LOSS OF REVENUE, LOSS OF DATA OR CONTENT, DAMAGES FOR BUSINESS INTERRUPTION, OR LOSS OR DECREASE IN VALUE OF ASSETS OR SECURITIES ARISING OUT OF ACCESS TO, USE OF, OR INABILITY TO USE THE COMPANY SITE, ANY INFORMATION CONTAINED ON THE SITE, ANY OFFERED SERVICES, OR OUT OF THE BREACH OF ANY WARRANTY, OR CAUSED BY ANY FAILURE OF PERFORMANCE, ERROR, ERROR OF INFORMATION, INCORRECT INFORMATION, OMISSION, INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OF TRANSMISSION, COMPUTER VIRUS, COMMUNICATION LINE FAILURE, THEFT, OR DESTRUCTION OR UNAUTHORIZED ACCESS TO THE SITE. 
THE LIMITATION ON THE COMPANY PARTIES’ LIABILITY ABOVE SHALL APPLY WHETHER OR NOT THE COMPANY PARTIES HAVE OR HAD BEEN ADVISED OF OR SHOULD HAVE BEEN AWARE OF THE POSSIBILITY OF ANY SUCH LOSSES ARISING. IN ANY EVENT, UNDER NO CIRCUMSTANCE SHALL THE COMPANY OR ANY COMPANY PARTY BE LIABLE FOR ANY LOSS, COST, EXPENSE, OR DAMAGE TO YOU OR ANY THIRD PARTY THAT EXCEEDS THE AMOUNT ACTUALLY PAID BY YOU TO THE COMPANY OR ANY ENTITY IN CONNECTION WITH THE EVENT GIVING RISE TO SUCH LIABILITY.
Some jurisdictions do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the limitation may not apply to you. IN THESE JURISDICTIONS, THE COMPANY PARTIES’ LIABILITY WILL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.

11. Indemnification
Your use of the site and any offered services constitutes your express agreement to indemnify, defend, and hold harmless the Company and the Company Parties as listed under “Limitation of Liability” above (collectively, the “Indemnified Parties”) from and against any and all claims, complaints, demands, actions, suits, and proceedings by any person, including any regulatory authority (each a “third party claim/proceeding”) and all resulting liabilities and obligations (including damages, administrative monetary penalties, financial sanctions, settlement payments, expenses and costs, including lawyer’s fees) arising from, connected with, or relating to any of the following: (a) the use by or on behalf of you of any offered services, (b) your reliance on any information contained in the site, or (c) any negligence, misconduct, or breach of this Agreement, or any actual or alleged violation of any applicable law or infringement of any rights, by or on behalf of you or any other person for whom you are responsible under this agreement or at law. The Company retains the right to participate (with counsel of their own selection at their sole cost and expense) in the defence of, and settlement negotiations relating to, any third party claim/proceeding.

12. Modification of these Terms
The Company reserves the right, at any time, to make changes or modifications to these Terms. Such changes will become effective upon its publication on the Company site. If you continue to use the site or any offered services after the date which it has been changed and published, the continued use shall be deemed to constitute acceptance of any such changes. You agree to review these Terms periodically to be aware of any such revisions.

13. Third-Party Services
The Company will never sell, rent, distribute, or disclose any of your personal information to any third parties, except in an effort to provide you with services you have requested. For further information on third-party services providers, please refer to our Privacy Policy. From time to time, the Company may provide you with access to third-party websites, networks, software, databases, applications, products, systems, servers, or services (collectively referred to as “Third-Party Websites”). The Company has no control over Third-Party Websites, and the Company makes no representations or warranties of any kind concerning such Third-Party Websites, including, without limitation, concerning their completeness and accuracy. 

You acknowledge and agree that your interactions with Third-Party Websites are solely between you and such websites. The Company is not responsible or liable in any matter for such interactions.

14.Governing Law
These Terms and Conditions shall be governed by the laws of the British Virgin Islands without regard to its conflicts of law principles. You hereby agree that any and all disputes and causes of action arising out of or connected with the services shall be governed by the substantive laws of the British Virgin Islands.

15. Severability
If any term, clause or provision in these Terms is held invalid or unenforceable, then it will be severable from these terms and will not affect the validity or enforceability of any remaining part of that term, clause, or provision or any other term, clause, or provision contained in these terms.

16. Termination
These Terms are effective unless and until terminated by either you or the Company. You may terminate these Terms at any time by discontinuing your use of the site and related services. The Company reserves the right, in its sole discretion and without notice, to terminate or suspend your right to use the site or any offered services, at any time and for any or no reason. You acknowledge and agree that the Company shall have no liability or obligation to you in such event. 

17. Entire Agreement
These Terms constitute the entire agreement between you and the Company with respect to your access and use of the site and any offered services. These Terms or any rights and licenses granted in them, may not be transferred or assigned by you without the prior written consent of the Company. Any failure on the Company’s part to exercise or enforce any part of these Terms shall not constitute a waiver of any portion of these Terms, and the Company reserves the right to enforce any part of these Terms at any time. 

These Terms and Conditions were updated on October 29, 2021.
      </pre>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Terms',
  version: '202110290648',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    connectWallet: Function,
  },
}
</script>
