import './index.css'
import './nes.min.css'
import 'vue2-animate/dist/vue2-animate.min.css'
import 'primevue/resources/themes/tailwind-light/theme.css' //theme
import 'primevue/resources/primevue.min.css' //core css
import 'primeicons/primeicons.css'

import PrimeVue from 'primevue/config'
import Timeline from 'primevue/timeline'
import { createApp } from 'vue'
import VueSocialSharing from 'vue-social-sharing'
import VueClickAway from 'vue3-click-away'
import VueClipboard from 'vue3-clipboard'

import { FontAwesomeIcon } from '@/plugins/font-awesome'

import App from './App.vue'
import Maintenance from './components/Maintenance'
import router from './router'

if (process.env.VUE_APP_MAINTENANCE === 'true') {
  console.log('process', process.env)
  const app = createApp(Maintenance)

  app
    .use(router)
    .use(PrimeVue)
    .use(VueClickAway)
    .use(VueClipboard, {
      autoSetContainer: true,
      appendToBody: true,
    })
    .component('fa', FontAwesomeIcon)
    .component('Timeline', Timeline)
    .mount('#app')
} else {
  const app = createApp(App)

  app
    .use(router)
    .use(PrimeVue)
    .use(VueClickAway)
    .use(VueSocialSharing)
    .use(VueClipboard, {
      autoSetContainer: true,
      appendToBody: true,
    })
    .component('fa', FontAwesomeIcon)
    .component('Timeline', Timeline)
    .mount('#app')
}
