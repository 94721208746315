<template>
<div class="m-16">
  <h2>This app is down temporarily for maintenance.</h2>
</div>
</template>

<script>
export default {
  name: 'Maintenance',
}
</script>

<style scoped>
/* .router-link-active {
    text-decoration: underline;
    color: white;
  } */
/* footer{
    width:100%;
  } */
</style>
