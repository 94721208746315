export default (state) => {
  const links = [
    {
      label: 'Disclaimer',
      path: '/disclaimer',
      wallet: false,
    },
    {
      label: 'Terms & Conditions',
      path: '/terms',
      wallet: false,
    },
    {
      label: 'Privacy Policy',
      path: '/privacy',
      wallet: false,
    },
  ]
  return links.filter((link) => {
    if (!state.userAddress) {
      return !link.wallet
    }
    return link.path !== '/'
  })
}
