<template>
  <div
    class="
      grid grid-cols-1
      md:grid-cols-1
      gap-12
      items-center
      justify-between
      text-left
      mt-12
      pb-32
    "
  >
    <h1 class="text-3xl text-center">Debates Gameplay</h1>
    <p>
      For a more comprehensive version, checkout the <a href="https://pixelpotus.gitbook.io/debates-wiki/">PixelDebates Rules</a>!
      </p>
    <h1 class="text-2xl">Overview</h1>
    <p>
      Debates are short (5-10 min) PvP card battle games where each player tries to exhaust the
      other player’s fortitude (a value representing combined HP & Mana) while managing their own.
      Each player brings a champion (a POTUS selected from their collection) and a 15-card deck of
      debate tactics.
    </p>
    <div class="nes-container is-rounded flex flex-col items-center justify-center">
      <img src="../assets/01_MenuBG_alt.jpg" />
    </div>

    <h1 class="text-3xl">Champions</h1>
    Champions are epic level (or above) POTUSes in your PixelPotus collection. You can still play if
    you don't own an epic level (or above), but you will pay a 5% fee from any winnings to the POTUS
    lending pool. Each POTUS will have different attributes during gameplay:
    <h2 class="text-xl pl-8">Champion Attributes</h2>
    <ul class="list-disc list-inside pl-8">
      <li>Fortitude: combination of HP & Mana</li>
      <li>Affinities: towards certain debate tactics (applies a multiplier)</li>
      <li>Special Abilities: 1-time use or passive abilities</li>
    </ul>
    The game will launch with the following 9 POTUS champions, but more will be added over time as
    their abilites are playtested and balanced.
    <div class="flex flex-col items-center justify-center">
      <span class="p-2">Click a POTUS to see their in-game stats!</span>
      <span class="p-2 text-xs"
        >*All stats on POTUSes and TACTICs are subject to balancing changes to maintain
        fairness.</span
      >
      <div class="grid sm:grid-cols-1 md:grid-cols-3">
        <div
          v-for="(c, key) in state.champions"
          :key="key"
          class="p-2 border-2 border-dotted"
          :class="{ 'bg-white': c.show }"
        >
          <button class="w-full hover:bg-white p-2" @click="c.show = !c.show">
            <img style="height: 100%" :src="c.img" />
          </button>
          <div v-if="c.show">
            <b>{{ c.name }}</b>
            <span class="text-xs">
              <div class="flex py-1">
                <div><b>Fortitude:</b></div>
                <div class="flex-grow border-black border-b-2 border-dotted"></div>
                <div>{{ c.fp }}</div>
              </div>
              <div class="flex py-1">
                <div><b>GF Buff:</b></div>
                <div class="flex-grow border-black border-b-2 border-dotted"></div>
                <div>{{ c.gf }}</div>
              </div>
              <div class="flex py-1">
                <div><b>BF Buff:</b></div>
                <div class="flex-grow border-black border-b-2 border-dotted"></div>
                <div>{{ c.bf }}</div>
              </div>
              <div class="flex flex-col py-1">
                <div><b>Abilities:</b></div>
                <div class="text-xs">{{ c.abilities }}</div>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>

    <h1 class="text-3xl">Debate Tactics</h1>
    Tactics are classical and modern debate strategies broken down into 2 categories: arguments and rhetorical devices. Arguments have alignments, while RD are neutral.
    <h2 class="text-xl pl-8">Types</h2>
    <ul class="list-disc list-inside pl-8">
      <li>Good Faith Arguments (GF): blue cards with a dove icon.</li>
      <li>Bad Faith Arguments (BF): red cards with a fire icon.</li>
      <li>Rhetorical Devices (RD): purple cards with a great eye icon.</li>
    </ul>
    <h2 class="text-xl pl-8">Attributes</h2>
    <ul class="list-disc list-inside pl-8">
      <li>Strength: the amount of damage that it will inflict on your opponent's fortitude.</li>
      <li>Cost: the amount of fortitude it will cost you to play it.</li>
      <li>
        Effect: not all cards have an effect beyond dealing damage. Specific effects vary on those
        that do.
      </li>
    </ul>
    <div class="nes-container is-rounded flex flex-col items-center justify-center">
      <img class="" style="max-height: 400px" src="../assets/card.png" />
    </div>
    <p>
      Cards come in 4 rarity levels: Fragments and 1, 2 or 3 Star. Fragments are not complete cards
      and cannot be used in a deck. Players must hunt down all 3 distinct fragments of a particular
      tactic and forge them into a 1 Star tactic.
    </p>
    <p>
      1 Star tactics have a base strength. If upgraded to 2 Star, that strength gets +1. A 3 Star
      would get +2. Cards can be upgraded by combining multiples of the same tactic and paying a fee
      in $PXL.
    </p>
    <p>
      In addition to any effects, all RD cards have the same innate ability: they affect the mood of
      the crowd. See more in The Crowd section below.
    </p>

    <h1 class="text-3xl">The Crowd</h1>
    <p>
      There is always a crowd of spectators watching a debate! The crowd is represented by a meter
      that scales from Irrational Mob (IM) to Enlightened Citizens (EC). The crowd spectrum from top
      to bottom applies a strength multiplier on cards: If the crowd is enlightened, card strength
      is multiplied and added if it’s a GF (good faith) card, but multiplied and subtracted if it’s
      a BF (bad faith) card, and vice versa if the crowd is irrational.
    </p>
    <p>
      Players can "work the crowd" by playing Rhetorical Device (RD) tactics. These cards will shift
      the crowd in one direction or another depending on the alignment of the card they are played
      on.
    </p>

    <h1 class="text-3xl">Gameplay</h1>
    <p>
      Heres the skinny: once you have collected enough cards to make a deck and assign a POTUS to
      it, head over to the PvP arena and match for stakes. !!ALERT *A WILD POTUS
      APPEARS* ALERT!! Game board loads, the match begins…
    </p>

    <div class="nes-container is-rounded flex flex-col items-center justify-center">
      <img src="../assets/03_Arena.jpg" />
    </div>

    <ol class="list-decimal list-inside">
      <li>Almighty RNG determines player order and starting crowdstate.</li>
      <li>
        Players get 2 turns followed by a Potus phase (where the fastest and most furious clickers
        prevail)!
      </li>
      <li>
        Player 1 gets to set the “tone” of the debate by using arguments to create threads (columns
        where cards can be played that round).
      </li>
      <li>
        HOWEVER, Player 2 gets the LAST WORD, so they can claim a petty victory and hide behind the
        moderator while you sputter and fume. But don't worry, the roles reverse next round!
      </li>
    </ol>

    TURN ORDER:
    <ol class="list-decimal list-inside">
      <li>
        Player 1 creates threads and plays RD on top of arguments as buffs, (or holds RD for cross
        examination). PASS. Player 2 presents counterarguments to “block” Player 1’s arguments.
      </li>
      <li>
        Player 2 must assign at least 1 blocker, but may hold other arguments for blocking later.
        PASS. Player 1 slides in with their 2nd phase: cross-examination. They may play arguments
        from their hand on any of their threads where the argument was removed by the 2nd player.
        Last chance to play RD on threads! PASS.
      </li>
      <li>
        Player 2 comes in with a cross-examination of their own and gets a last word on the board
        state. They play blocking arguments, RD, shwatever. PASS.
      </li>
    </ol>
    <div class="nes-container is-rounded flex flex-col items-center justify-center">
      <img src="../assets/board.jpg" />
    </div>
    <p>
      POTUS PHASE: Keep in mind, you may use an activatable POTUS ability on either of your 2 turns
      by clicking on the POTUS ability button. However it might be better to wait until the POTUS phase.
      Both players get 10 seconds for last minute shenanigans with POTUS abilities.
    </p>

    <p>
      ROUND END: As the first order of business, adjust the Crowd. Rhetorical Devices (RD) are
      naturally persuasive, so adjust the crowd based on how many RD are on the board. For instance,
      if the crowd is currently at 7% Irrational Mob, and there are a total of 3 RD on Bad Faith
      (BF) arguments, and 2 RD on Good Faith (GF) arguments, the crowd shifts a net 1% towards the
      Mob, making it 8%.
    </p>

    <p>
      Next, add 8% Strength to all Bad Faith arguments, and subtract 8% Strength from all Good Faith
      arguments (round to the nearest whole number). Next, add any POTUS alignment buffs/nerfs and
      any special ability buffs/nerfs. Finally, tally up the total Strength of each thread and
      subtract it from the opponent's thread. The remainder is called “overage” and tramples over
      the opponent’s thread to damage their Fortitude. After overage is subtracted from the
      Fortitude bar, players subtract fortitude equal to the summed cost of their tactic cards.
    </p>

    <p>
      If both players are above zero, cards go to the discard pile and another round initiates where
      players draw up to 5. Player order then rotates. Players may over-exert and spend themselves
      into negative Fortitude. When a player reaches zero or below at the end of round, the player
      with the least damaged psyche wins!
    </p>

    <p>
      "End Game" lobby: winner takes 90% of the pot (or 85% if they used Rent-a-POTUS). The
      remainder is pooled and shared amongst top POTUS holders:
    </p>
    <ul class="list-disc list-inside pl-8">
      <li>Unique holders share 5%.</li>
      <li>Legendary holders share 5%.</li>
      <li>Legendary holders also share the extra 5% from Rent-a-POTUS if present.</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Gameplay',
  components: {},
  data() {
    return {
      state: this.$root.$data.state,
    }
  },
  async mounted() {},
  methods: {},
}
</script>
