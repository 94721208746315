<template>
  <div
    class="
      grid grid-cols-1
      gap-12
      items-center
      justify-between
      text-left
      mt-12
      overflow-hidden
      pb-32
    "
  >
    <h1 class="text-3xl text-center">Leading Constituents</h1>

    <SpeechBubble :img="state.images.wilson" :show="showHelp" :potus="'Pres. Wilson'">
      “You are not here merely to make a living. You are here in order to enable the world to live
      more amply, with greater vision, with a finer spirit of hope and achievement. You are here to
      enrich the world, and you impoverish yourself if you forget the errand.” ― Woodrow Wilson
      <a href="#" class="text-blue-link" @click="toggleHelp">Cool</a>
    </SpeechBubble>

    <div v-if="loading" class="text-center">Loading...</div>

    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 text-center">
      <a
        href="#"
        class="text-blue-500 hover:text-blue-600"
        :class="{ 'underline text-gray-900': view == 'weighted' }"
        @click="changeView('weighted')"
        >Most Rare</a
      >
      <a
        href="#"
        class="text-blue-500 hover:text-blue-600"
        :class="{ 'underline text-gray-900': view == 'total' }"
        @click="changeView('total')"
        >Biggest</a
      >
      <a
        href="#"
        class="text-blue-500 hover:text-blue-600"
        :class="{ 'underline text-gray-900': view == 'barters' }"
        @click="changeView('barters')"
        >Top Barterers</a
      >
      <a
        href="#"
        class="text-blue-500 hover:text-blue-600"
        :class="{ 'underline text-gray-900': view == 'sales' }"
        @click="changeView('sales')"
        >Top Capitalists</a
      >
    </div>

    <div v-if="myRank !== -1" class="rounded-full py-2 px-3 bg-black text-white m-auto">
      Your Rank: #{{ myRank }}
    </div>
    <div class="flex flex-col">
      <Pagination
        :offset="offset"
        :limit="limit"
        :pages="pages"
        class="-mt-10"
        @firstPage="firstPage"
        @prevPage="prevPage"
        @nextPage="nextPage"
        @lastPage="lastPage"
        @pageSizeSelected="pageSizeSelected"
      >
        <template #perPageOptions>
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </template>
      </Pagination>
      <div class="hidden lg:inline-block -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-2 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200 text-sm lg:text-base">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-600
                      uppercase
                      tracking-wider
                    "
                  >
                    Rank
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-600
                      uppercase
                      tracking-wider
                    "
                  >
                    Owner
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-600
                      uppercase
                      tracking-wider
                    "
                  >
                    Cards
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-600
                      uppercase
                      tracking-wider
                    "
                  >
                    Rarity Score
                    <!-- <span class="inline"
                      ><Popper placement="top" :hover="false" :arrow="true" class="inline">
                        <button class="text-blue-link hover:text-blue-hov">
                          <fa icon="info-circle" class="align-middle" />
                        </button>
                        <template #content>
                          <div class="nes-container is-rounded is-dark" style="width: 44rem">
                            <p>
                              Rarity score is a weighted factorial where weight is the required
                              upgrade to reach the level * 1.5.
                              <br /><br />1x Unique = 6 * (20*1.5) * 5 * 4 * 3 * 2 * 1 <br />1x
                              Legendary = 5 * (5*1.5) * 4 * 3 * 2 * 1 <br />1x Epic = 4 * (2*1.5) *
                              3 * 2 * 1 <br />1x Rare = 3 * (2*1.5) * 2 * 1 <br />1x Uncommon = 2 *
                              (2*1.5) * 1 <br />1x Common = 1 * 1
                            </p>
                          </div>
                        </template>
                      </Popper></span
                    > -->
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-600
                      uppercase
                      tracking-wider
                    "
                  >
                    Trades
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-600
                      uppercase
                      tracking-wider
                    "
                  >
                    Barters
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-600
                      uppercase
                      tracking-wider
                    "
                  >
                    Sales
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="(entry, key) in leaders" :key="key">
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="ml-4">
                        <div class="text-sm font-medium text-gray-900">
                          {{ entry.rank }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      <AddrIcon :address="entry.id" :alias="entry.alias" />
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      {{ entry.total }}
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      {{ entry.weighted }}
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      {{ entry.trades }}
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      {{ entry.barters }}
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      {{ state.convertFromMutez(entry.sales) }}tez
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:hidden gap-2">
        <div
          v-for="(entry, key) in leaders"
          :key="key"
          class="nes-container is-rounded"
          :class="{ 'is-dark': entry.id === state.userAddress }"
        >
          <div class="flex text-left text-xs items-center">
            <div class="flex-shrink mr-2 text-xl">#{{ entry.rank }}</div>
            <div class="flex-grow">
              <span class="text-base"
                ><router-link :to="'/collection/' + entry.id"
                  ><AddrIcon :address="entry.id" :alias="entry.alias" /></router-link></span
              ><br />
              <span class="text-base">{{ entry.total }}</span> TACTICs<br />
              <span class="text-base">{{ entry.trades }}</span> trades<br />
              <span class="text-base">{{ entry.barters }}</span> barters<br />
              <span class="text-base">{{ state.convertFromMutez(entry.sales) }}ꜩ</span>
              in sales<br />
            </div>
          </div>
        </div>
      </div>
      <Pagination
        :offset="offset"
        :limit="limit"
        :pages="pages"
        @firstPage="firstPage"
        @prevPage="prevPage"
        @nextPage="nextPage"
        @lastPage="lastPage"
        @pageSizeSelected="pageSizeSelected"
      >
        <template #perPageOptions>
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </template>
      </Pagination>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { find } from 'lodash'

import AddrIcon from '../components/AddrIcon'
import Pagination from '../components/Pagination'
import SpeechBubble from '../components/SpeechBubble'

export default {
  name: 'Leaders',
  components: {
    AddrIcon,
    Pagination,
    SpeechBubble,
  },
  data() {
    return {
      state: this.$root.$data.state,
      view: 'weighted',
      leaders: [],
      total: 0,
      myRank: -1,
      loading: false,
      showHelp: true,
      filter: '',
      offset: 0,
      limit: 20,
    }
  },
  computed: {
    pages() {
      if (!this.leaders) {
        return 1
      }
      let tot = parseInt((this.total / this.limit).toFixed(0))
      if (tot < this.total / this.limit) {
        tot++
      }
      return tot
    },
  },
  async mounted() {
    const sh = localStorage.getItem('showLeadersHelp')
    this.showHelp = sh ? JSON.parse(sh) : true
    await this.getLeaders()
  },
  methods: {
    nextPage() {
      this.offset += this.limit
      if (this.offset >= this.total) {
        this.offset -= this.limit
      }
      this.getLeaders()
    },
    prevPage() {
      let offset = this.offset - this.limit
      if (offset < 0) {
        this.offset = 0
      } else {
        this.offset = offset
      }
      this.getLeaders()
    },
    firstPage() {
      this.offset = 0
      this.getLeaders()
    },
    lastPage() {
      this.offset = (this.pages - 1) * this.limit
      this.getLeaders()
    },
    async pageSizeSelected(value) {
      this.offset = 0
      this.limit = parseInt(value)
      this.getLeaders()
    },
    changeView(v) {
      this.view = v
      this.leaders = []
      this.total = 0
      this.offset = 0
      this.myRank = -1
      this.getLeaders()
    },
    async refresh() {
      await this.getLeaders()
    },
    toggleHelp() {
      this.showHelp = false
      localStorage.setItem('showLeadersHelp', JSON.stringify(false))
    },
    async getLeaders() {
      console.log('aliases leng', this.state.aliases.length)
      if (this.state.aliases.length === 0) {
        this.state.log('load leaders')
        await new Promise((resolve) => setTimeout(resolve, 500))
        await this.getLeaders()
      }
      this.loading = true
      try {
        let resp = await axios({
          url: '/api/leaders',
          params: {
            orderBy: this.view,
            limit: this.limit,
            offset: this.offset,
          },
        })
        this.leaders = resp.data.leaders.map((l) => {
          const alias = find(this.state.aliases, (a) => {
            return a.address == l.id
          })
          if (alias) {
            l.alias = alias.alias
          }
          return l
        })
        this.total = resp.data.total

        this.loading = false

        if (this.state.userAddress && this.myRank == -1) {
          resp = await axios({
            url: '/api/myRank',
            params: {
              orderBy: this.view,
              owner: this.state.userAddress,
            },
          })
          this.myRank = resp.data.rank
        }
      } catch (error) {
        this.loading = false
      }
    },
  },
}
</script>
