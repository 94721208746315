<template>
  <div
    class="
      grid grid-cols-1
      md:grid-cols-1
      items-center
      justify-between
      text-left
      mt-6
      overflow-hidden
      pb-12
    "
  >
    <div v-if="loading" class="text-center">Loading...</div>
    <div v-else-if="token !== null">
      <div class="grid lg:grid-cols-2 sm:grid-cols-1 gap-6">
        <img
          class="m-auto"
          :class="{ 'vibrate-1': upgrading, 'bounce-in-top': !upgrading }"
          :src="
            token.metadata.animatedUri ? token.metadata.animatedUri : token.metadata.artifactUri
          "
        />
        <div class="text-center nes-container is-rounded bg-brown-dark text-white p-5">
          <h1 class="text-2xl text-center">
            {{ token.metadata.name.split(' - ')[0] }}<br />
            <span class="text-xl text-gray-400">({{ token.rarity }})</span>
          </h1>
          <div
            v-if="token.stats"
            class="mt-5 p-2 border-2 border-dashed border-black text-base sm:text-xl"
          >
            Global Stats<br />
            <span class="inline text-center text-xs">
              Last refresh: {{ statsTimestamp.fromNow() }}
              <button class="text-blue-link hover:text-blue-hov" @click="refresh">
                <fa icon="sync" class="h-5 w-5 align-bottom" />
              </button>
            </span>
            <div class="grid grid-cols-2 text-gray-600">
              <div class="text-right">cap:</div>
              <div class="text-left text-green-800">
                {{ token.stats.cap }}
              </div>
            </div>
            <div class="grid grid-cols-2 text-gray-600">
              <div class="text-right">minted:</div>
              <div class="text-left text-green-800">
                {{ token.stats.minted }}
              </div>
            </div>
            <div v-if="!isUnique" class="grid grid-cols-2 text-gray-600">
              <div class="text-right">burned:</div>
              <div class="text-left text-green-800">
                {{ token.stats.burned }}
              </div>
            </div>
            <div class="grid grid-cols-2 text-gray-600">
              <div class="text-right">trades:</div>
              <div class="text-left text-green-800">
                {{ token.stats.trades ? token.stats.trades : '-' }}
              </div>
            </div>
            <div class="grid grid-cols-2 text-gray-600">
              <div class="text-right">sales:</div>
              <div class="text-left text-green-800">
                {{ token.stats.sales ? token.stats.sales : '-' }}
              </div>
            </div>
            <div class="grid grid-cols-2 text-gray-600">
              <div class="text-right">high:</div>
              <div class="text-left text-green-800">
                {{ token.stats.high ? state.convertFromMutez(token.stats.high) + 'tez' : '-' }}
              </div>
            </div>
            <div class="grid grid-cols-2 text-gray-600">
              <div class="text-right">low:</div>
              <div class="text-left text-green-800">
                {{ token.stats.low ? state.convertFromMutez(token.stats.low) + 'tez' : '-' }}
              </div>
            </div>
            <div class="grid grid-cols-2 text-gray-600">
              <div class="text-right">last:</div>
              <div class="text-left text-green-800">
                {{ token.stats.last ? state.convertFromMutez(token.stats.last) + 'tez' : '-' }}
              </div>
            </div>
          </div>
          <!-- <div
            v-if="token.rarity !== 'unique'"
            class="mt-5 p-2 border-2 border-dashed border-black text-xl"
          >
            Upgrade Requirements
            <div class="text-gray-900">
              <img width="20" class="inline mr-2 mb-2" src="../assets/flame.png" />{{
                token.upgradeReq
              }}
              {{ token.rarity }}s
            </div>
            <div class="squared-full text-green-800">
              <fa icon="coins" class="h-5 w-5 mr-1" />{{ state.convertFromMutez(token.upgradeCost)
              }}<span class="text-gray-600">tez</span>
            </div>
            <span class="text-base text-gray-900">=1x {{ token.upgradeTo }}</span>
          </div> -->
          <div
            v-if="state.userAddress && !isUnique"
            class="mt-5 p-2 border-2 border-dashed border-black sm:text-xl"
          >
            <div v-if="!upgrading">
              Your Balance: <span class="text-green-800">{{ token.balance }}</span>
              <!-- <br />
              <div v-if="token.balance >= token.upgradeReq && token.rarity !== 'unique'">
                upgrade to {{ token.upgradeTo }}?
                <div class="grid sm:grid-cols-2 gap-6 p-4 text-gray-600">
                  <div>
                    <button
                      class="text-xs uppercase p-4 nes-btn is-primary w-full"
                      @click="upgrade(token)"
                    >
                      Upgrade Once
                    </button>
                  </div>
                  <div>
                    <button
                      class="text-xs uppercase p-4 nes-btn w-full"
                      @click="upgradeBatch(token)"
                    >
                      Upgrade up to 20
                    </button>
                  </div>
                </div>
              </div> -->
            </div>
            <span v-if="upgrading">
              <fa icon="cog" class="animate-spin h-5 w-5 mr-3" />
              Upgrading... Please wait for block confirmation.
            </span>
          </div>
          <div
            v-if="isUnique && owners.length"
            class="mt-5 p-2 border-2 border-dashed bg-black border-white text-white sm:text-xl"
          >
            Proudly Owned By
            <router-link :to="'/collection/' + owners[0].address">
              <AddrIcon :address="owners[0].address" :alias="owners[0].alias" />
            </router-link>
          </div>
          <div
            v-if="isUnique && trades.length"
            class="mt-5 p-2 border-2 border-dashed bg-black border-white text-white sm:text-xl"
          >
            Proudly Owned By
            <router-link :to="'/collection/' + trades[0].owner">
              <AddrIcon :address="trades[0].owner" :alias="trades[0].alias" />
            </router-link>
            <br />
            <a
              class="text-base uppercase p-4"
              :href="`/trades/${trades[0].owner}/${trades[0].tradeId}`"
              target="_blank"
            >
              On sale for {{ state.convertFromMutez(trades[0].price_accepted) }}tz<fa
                icon="external-link-alt"
                class="ml-1 align-baseline"
              />
            </a>
            <br />
            <span v-if="payout == null" class="text-xs">(Includes 25% treasury claim!)</span>
            <span v-else class="text-xs">(Treasury already claimed.)</span>
          </div>
          <div
            v-if="isUnique && auctions.length"
            class="mt-5 p-2 border-2 border-dashed bg-black border-white text-white sm:text-xl"
          >
            Proudly Owned By
            <router-link :to="'/collection/' + auctions[0].seller">
              <AddrIcon :address="auctions[0].seller" :alias="auctions[0].alias" />
            </router-link>
            <br />
            <a
              class="text-sm uppercase p-4 text-blue-link"
              :href="`/auctions/${auctions[0].id}`"
              target="_blank"
            >
              On auction for {{ state.convertFromMutez(auctions[0].bid_amount) }}tz<fa
                icon="external-link-alt"
                class="ml-1 align-baseline"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center">TACTIC Not Found</div>
  </div>
</template>

<script>
import { OpKind } from '@taquito/taquito'
import axios from 'axios'
import { find, findIndex } from 'lodash'

import AddrIcon from '../components/AddrIcon'

export default {
  name: 'Token',
  components: {
    AddrIcon,
  },
  data() {
    return {
      state: this.$root.$data.state,
      tokenId: null,
      token: null,
      loading: true,
      upgrading: false,
      isUnique: false,
      owners: [],
      trades: [],
      auctions: [],
      payout: null,
    }
  },
  computed: {
    statsTimestamp() {
      return this.state.statsTimestamp
    },
  },
  watch: {
    $route() {
      this.getId()
    },
  },
  async mounted() {
    this.state.log(this.$route.params)
    this.getId()
  },
  methods: {
    async getId() {
      try {
        if (this.$route.params.tokenId) {
          this.tokenId = parseInt(this.$route.params.tokenId)
          if (
            this.state.allTokens.length === 0 ||
            this.state.stats?.series?.length === 0 ||
            this.state?.aliases?.length === 0
          ) {
            this.loading = true
            await new Promise((resolve) => setTimeout(resolve, 500))
            return this.getId()
          }
          this.token = find(this.state.allTokens, (tok) => {
            return tok.tokenId === this.tokenId
          })

          // this.state.log()
          document.getElementById('ogUrl').setAttribute('content', `/token/${this.tokenId}`)
          document
            .getElementById('ogImg')
            .setAttribute('content', `${this.token.metadata.thumbnailUri}`)

          this.token.balance = 0
          if (this.state.userAddress) {
            this.getBalance()
          }

          if (this.tokenId > 6000) {
            this.isUnique = true
            const owners = await axios({
              url: '/api/tokenOwners',
              params: {
                tokenId: this.tokenId,
              },
            })
            if (owners.data.owners.length > 0) {
              this.owners = owners.data.owners.map((o) => {
                const alias = find(this.state.aliases, (a) => {
                  return a.address === o.address
                })
                if (alias) {
                  o.alias = alias.alias
                }
                return o
              })
            } else {
              const resp = await axios({
                url: '/api/trades',
                params: {
                  limit: 1,
                  filter: '' + this.tokenId,
                  filterAccept: false,
                },
              })
              if (resp.data.trades.length > 0) {
                this.trades = resp.data.trades.map((trade) => {
                  const alias = find(this.state.aliases, (a) => {
                    return a.address === trade.owner
                  })
                  if (alias) {
                    trade.alias = alias.alias
                  }
                  return trade
                })
                await this.getPayoutStatus()
              } else {
                const resp = await axios({
                  url: '/api/auctions',
                  params: {
                    limit: 1,
                    filter: '' + this.tokenId,
                  },
                })
                if (resp.data.auctions.length > 0) {
                  this.auctions = resp.data.auctions.map((auction) => {
                    const alias = find(this.state.aliases, (a) => {
                      return a.address === auction.seller
                    })
                    if (alias) {
                      auction.alias = alias.alias
                    }
                    return auction
                  })
                  await this.getPayoutStatus()
                }
              }
            }
          }

          const seriesId = this.tokenId % 1000
          const series = find(this.state.stats.series, (s) => {
            return s.seriesId === seriesId
          })
          if (series) {
            this.token.stats = find(series.levels, (l) => {
              return l.tokenId === this.tokenId
            })
          }
          this.state.log(this.token)
          this.loading = false
        } else {
          this.tokenId = null
          this.token = null
        }
      } catch (error) {
        this.loading = false
      }
    },
    async getPayoutStatus() {
      try {
        const resp = await axios({
          url: '/api/treasury',
        })
        this.payout = find(resp.data.treasury.payouts, (p) => {
          return this.tokenId === p.tokenId
        })
      } catch (error) {
        console.error(error)
      }
    },
    async refresh() {
      this.loading = true
      this.token = null
      await this.state.loadStats(true)
      this.getId()
    },
    async getBalance() {
      if (this.state.myTokens.length === 0) {
        await new Promise((resolve) => setTimeout(resolve, 500))
        return this.getBalance()
      }
      this.state.log('get balance', this.state.myTokens, this.tokenId)
      const mt = find(this.state.myTokens, (tok) => {
        return tok.tokenId === this.tokenId
      })
      if (mt) {
        this.token.balance = mt.balance
      }
    },
    async upgrade(token) {
      if (this.state.balance < token.upgradeCost) {
        alert(
          `You're wallet balance is ${this.state.convertFromMutez(
            this.state.balance
          )}. You need more tezzies to upgrade!`
        )
        return
      }
      this.upgrading = true
      this.state.loading = true
      try {
        const opts = {}
        opts.amount = token.upgradeCost
        opts.mutez = true
        // opts.storageLimit = this.state.storageLimit
        let op = await this.state.ppMain.methods.upgrade(token.tokenId).send(opts)
        await op.confirmation(1)
        this.state.loading = false
        this.upgrading = false

        token.balance -= token.upgradeReq

        const usrName = this.state.userAlias
          ? this.state.userAlias
          : this.state.formattedAddr(this.state.userAddress)
        const action = `${usrName} burned ${token.upgradeReq} ${token.rarity} ${token.metadata.name}s and minted 1 ${token.upgradeTo}. :rocket:`
        await axios({
          method: 'post',
          url: '/api/sendActivity',
          data: {
            content: action,
          },
        })

        const upgradedTokenId = token.tokenId + 1000
        const upgradedToken = find(this.state.allTokens, (tok) => {
          return tok.tokenId === upgradedTokenId
        })
        let idx = findIndex(this.state.myTokens, (mt) => mt.tokenId === upgradedToken.tokenId)
        if (idx > -1) {
          this.state.myTokens[idx].balance += 1
        } else {
          this.state.myTokens.push({ ...upgradedToken, balance: 1, upgrading: false })
        }

        if (token.balance === 0) {
          idx = findIndex(this.state.myTokens, (mt) => {
            return mt.tokenId === token.tokenId
          })
          this.state.myTokens.splice(idx, 1)
        }

        this.state.sortTokens(this.state.sortBy, true)
        setTimeout(() => {
          this.state.loadMyTokens(true, true)
        }, 15000)

        this.$router.push(`/token/${upgradedTokenId}`)
        this.$forceUpdate()
      } catch (e) {
        this.upgrading = false
        this.state.loading = false
        console.error('Unable to upgrade token', e)
        if (e.name && e.name === 'BeaconWalletNotInitialized') {
          this.state.userAddress = null
          this.$router.push('/')
        }
      }
    },
    async upgradeBatch(token) {
      if (this.state.balance < token.upgradeCost) {
        alert(
          `You're wallet balance is ${this.state.convertFromMutez(
            this.state.balance
          )}. You need more tezzies to upgrade!`
        )
        return
      }
      this.upgrading = true
      this.state.loading = true
      try {
        const opts = {}
        opts.amount = token.upgradeCost
        opts.mutez = true
        // opts.storageLimit = this.state.storageLimit

        const transactions = []
        let total = (token.balance - (token.balance % token.upgradeReq)) / token.upgradeReq
        if (total > 20) {
          total = 20
        }
        this.state.log('total', total)
        for (let i = 0; i < total; i++) {
          transactions.push({
            kind: OpKind.TRANSACTION,
            ...this.state.ppMain.methods.upgrade(token.tokenId).toTransferParams(),
            ...opts,
          })
        }

        const batch = await this.state.tezos.wallet.batch(transactions)
        let op = await batch.send()
        await op.confirmation(1)
        this.state.loading = false
        this.upgrading = false
        token.balance -= total * token.upgradeReq

        const usrName = this.state.userAlias
          ? this.state.userAlias
          : this.state.formattedAddr(this.state.userAddress)
        const action = `${usrName} burned ${total * token.upgradeReq} ${token.rarity} ${
          token.metadata.name
        }s and minted ${total} ${token.upgradeTo}s. :rocket:`
        await axios({
          method: 'post',
          url: '/api/sendActivity',
          data: {
            content: action,
          },
        })

        const upgradedTokenId = token.tokenId + 1000
        const upgradedToken = find(this.state.allTokens, (tok) => {
          return tok.tokenId === upgradedTokenId
        })
        let idx = findIndex(this.state.myTokens, (mt) => mt.tokenId === upgradedToken.tokenId)
        if (idx > -1) {
          this.state.myTokens[idx].balance += 1
        } else {
          this.state.myTokens.push({ ...upgradedToken, balance: 1, upgrading: false })
        }

        if (token.balance === 0) {
          idx = findIndex(this.state.myTokens, (mt) => {
            return mt.tokenId === token.tokenId
          })
          this.state.myTokens.splice(idx, 1)
        }

        this.state.sortTokens(this.state.sortBy, true)
        setTimeout(() => {
          this.state.loadMyTokens(true, true)
        }, 15000)

        this.$router.push(`/token/${upgradedTokenId}`)
        this.$forceUpdate()
      } catch (e) {
        this.upgrading = false
        this.state.loading = false
        console.error('Unable to upgrade token', e)
        if (e.name && e.name === 'BeaconWalletNotInitialized') {
          this.state.userAddress = null
          this.$router.push('/')
        }
      }
    },
  },
}
</script>
